import { useState, ChangeEvent, useEffect } from "react"
import {getAuthToken, getsales} from "../utils/functions"
import {AuthTokenType,FranchiseeProps,UserProps,salesProps} from "../utils/types"
import { useGetFranchisee, useGetUsers, useGetsales} from "../utils/hooks"
import { Button,Drawer,Input, Modal, Table, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { onhandUrl, salesorderUrl, salesorderupdatesUrl, sendsoUrl, viewpoUrl, viewsoUrl} from "../utils/network"
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import SpeedDialAction from "@mui/material/SpeedDialAction";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileSaver from "file-saver"; // Import FileSaver.js library

import {Select} from 'antd'
import { Link } from "react-router-dom"
import EmployeeSelectionDrawer from "../components/EmployeeSelectionDrawer"
import StripePaymentButton from "../components/PaymentButton"
import ChargeCustomer from "../components/CustomerChargeButton"
import OtherPayment from "../components/OtherPayment"
import DetailDrawer from "../components/DetailDrawer"
const { Option } = Select;


const formatsalesDataProps = (
  salesdata: salesProps[],
  updateemployee: (item: salesProps) => void,
  onCloseOrder: (salesid: salesProps) => void,
  onChangeAdd: (value: Date | null, sales_id: number) => void,
  onViewDetails: (item: salesProps) => void,
  onDeleteOrder: (item: salesProps) => void
) => {
  return salesdata.map((item) => {
    // If status is "Open" and payment mode is "credit_card", show StripePaymentButton and ChargeCustomer
    if (item.status === "Open" && item.mode === "credit_card") {
      return {
        ...item,
        key: item.id,
        change_delivery_date: (
          <div>
            <Input
              style={{ width: "150px" }}
              type="date"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeAdd(e.target.valueAsDate, item.id)
              }
            />
          </div>
        ),
        action: (
          <div>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => updateemployee(item)}
            >
              Allocate
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onCloseOrder(item)}
            >
              Close
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onViewDetails(item)}
            >
              Details
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onDeleteOrder(item)}
            >
              Delete
            </span>{" "}
            |{" "}
            {/* Show Stripe payment and Charge customer for credit card payments */}
            <StripePaymentButton salesId={item.id} /> |{" "}
            <ChargeCustomer salesId={item.id} />
          </div>
        ),
      };
    } 
    // If status is "Open" but mode is not "credit_card", show OtherPayment button
    else if (item.status === "Open") {
      return {
        ...item,
        key: item.id,
        change_delivery_date: (
          <div>
            <Input
              style={{ width: "150px" }}
              type="date"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeAdd(e.target.valueAsDate, item.id)
              }
            />
          </div>
        ),
        action: (
          <div>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => updateemployee(item)}
            >
              Allocate
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onCloseOrder(item)}
            >
              Close
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onViewDetails(item)}
            >
              Details
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onDeleteOrder(item)}
            >
              Delete
            </span>{" "}
            |{" "}
            {/* Show OtherPayment for non-credit-card payments */}
            <OtherPayment salesId={item.id} />
          </div>
        ),
      };
    } 
    // For other statuses (not Open), show only minimal actions
    else {
      return {
        ...item,
        key: item.id,
        action: (
          <div>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onViewDetails(item)}
            >
              Details
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onDeleteOrder(item)}
            >
              Delete
            </span>
          </div>
        ),
      };
    }
  });
};


function SalesOrderReview(){
  const userRole = localStorage.getItem('token1');

  const [loading,setLoading]=useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<salesProps | null>(null);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const[fetching,setFetching]=useState(true)
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  const [sales,setsales]=useState<salesProps[]>([])
  const [salesadd,setsalesadd]=useState<{[key:number]:Date|null}>([])
  useGetsales(setsales,setFetching,selectedFranchisee)

  useEffect(() => {
    getsales(setsales, setFetching,selectedFranchisee);
        },[selectedFranchisee])



  const handleUpdateSuccess = () => {
    getsales(setsales, setFetching, selectedFranchisee);
  };

        

  const sendemail= async (salesorder:salesProps|any)=>{

    const datatosend={
      sales_order_id:salesorder.id,
    }
  
      const headers=getAuthToken() as AuthTokenType
      
  
    const response:AxiosResponse =await axios.post(sendsoUrl,datatosend,headers).catch(
        (e)=>{ notification.error({
            message:"Operation Error",
            description:e.response?.data.error
        })
  
    }
        ) as AxiosResponse
  
        setLoading(false)
  
        if(response){
            notification.success({
                message:"Operation Success",
                description:"Email Sent Successfully"
                
            })
  
        }
      }

      const downloadso= async (salesorder:salesProps|any)=>{

        const datatosend={
          sales_order_id:salesorder.id,
        }
      
          const headers=getAuthToken() as AuthTokenType
          
      
        const response:AxiosResponse =await axios.post(viewsoUrl,datatosend,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.error
            })
      
        }
            ) as AxiosResponse
          const pdfBlob = new Blob([response.data], { type: "application/pdf" });
          FileSaver.saveAs(pdfBlob, `Sales_Order_${datatosend.sales_order_id}.pdf`);
                     setLoading(false)
      
          }
    



  const showModal = (item: salesProps) => {
    setCurrentItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };



  const getColumnFilterValues = (data: salesProps[], key: keyof salesProps) => {
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
};


      const salesordercolumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },

          {
            title: 'Expected Delivery Date',
            dataIndex: 'expected_delivery_date',
            key: 'expected_delivery_date',
          },
          {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            filters: getColumnFilterValues(sales, "customer_name").map((name) => ({ text: name, value: name })),
            onFilter: (value: string, record: salesProps) => record.customer_name === value,
      
          },
          
          {
            title: 'Actual Delivery Date',
            dataIndex: 'actual_delivery_date',
            key: 'actual_delivery_date',
          },
            
          {
            title: 'Employee',
            dataIndex: "employee_name",
            key: "employee_name",
            filters: getColumnFilterValues(sales, "employee_name").map((name) => ({ text: name, value: name })),
            onFilter: (value: string, record: salesProps) => record.employee_name === value,

          },

          
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
          },
          {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
          },
          {
            title: 'Pending',
            dataIndex: 'pending',
            key: 'pending',
          },
          {
            title: 'Mode of Payment',
            dataIndex: 'mode',
            key: 'mode',
          },          
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: 'Change Delivery Date',
            dataIndex: 'change_delivery_date',
            key: 'change_delivery_date',
          },

          {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
          },
        ];

        
const Closeorder= async (salesorder:salesProps|any)=>{

  const datatosend={
    id:salesorder.id,
    actual_delivery_date:salesadd[salesorder.id],
    status : "Closed",
  }

  for (var i=0; i < salesorder.salesorder_items.length; i++) {

    const datatosend2inv={
      sku_code_id:salesorder.salesorder_items[i].item.id,
      storage_location_id:salesorder.from_location.id,
      
      status:"Available",
      totalunits:-salesorder.salesorder_items[i].quantity
    }
    const headers=getAuthToken() as AuthTokenType
    
    const response:AxiosResponse =await axios.post(onhandUrl,datatosend2inv,headers).catch(
      (e)=>{ 
          notification.error({
          message:"Operation Error",
          description:e.response?.data.error
      })
  }
      ) as AxiosResponse
      setLoading(false)
  }
  
  setLoading(true)
  const headers=getAuthToken() as AuthTokenType

  const response:AxiosResponse =await axios.patch(salesorderupdatesUrl+'/'+datatosend.id,datatosend,headers).catch(
      (e)=>{ notification.error({
          message:"Operation Error",
          description:e.response?.data.error
      })

  }
      ) as AxiosResponse

      setLoading(false)

      if(response){
          notification.success({
              message:"Operation Success",
              description:"Order Closed Successfully"
              
          })

      }
      getsales(setsales, setFetching,selectedFranchisee);       
    }


    const DeleteOrder= async (salesorder:salesProps|any)=>{
      const confirmDeletion = () => {
        Modal.confirm({
          title: ("Confirm Deletion"),
          content: ("Are you sure you want to delete this order?"),
          onOk: async () => {
            try {
 

      const datatosend={
        id:salesorder.id,
      }
    
        
      const headers=getAuthToken() as AuthTokenType
    
      const response:AxiosResponse =await axios.delete(salesorderupdatesUrl+'/'+datatosend.id,headers).catch(
          (e)=>{ notification.error({
              message:"Operation Error",
              description:e.response?.data.error
          })
    
      }
          ) as AxiosResponse
    
          setLoading(false)
    
          if(response){
              notification.success({
                  message:"Operation Success",
                  description:"Order Deleted Successfully"
                  
              })
    
          }       
          getsales(setsales, setFetching,selectedFranchisee);       
        } catch (error) {
          notification.error({
            message: ("Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: ("Yes"),
      cancelText: ("No"),
    });
  };

  confirmDeletion();
};
    
  
             
  const onChangeAdd=(value:Date|null,sales_id:number)=>{
          setsalesadd({
            ...salesadd,
            [sales_id]:value
          })

        }
      
        const [updateEmployeeVisible, setUpdateEmployeeVisible] = useState(false);      
        const showUpdateEmployeeDrawer = (item: salesProps) => {
          setCurrentItem(item);
          setUpdateEmployeeVisible(true);
        };
        const handleUpdateEmployeeDrawerClose = () => {
          setUpdateEmployeeVisible(false);
        };      

        const handleUpdateEmployee = () => {
          // Call the function to fetch sales orders again
          getsales(setsales, setFetching,selectedFranchisee);
        };

        const downloadCSV = () => {
          const csvContent = "data:text/csv;charset=utf-8," +
            salesordercolumns.map(column => column.title).join(',') + '\n' +
            sales.map(row => salesordercolumns.map(column => {
              return `"${row[column.dataIndex as keyof salesProps]}"`;
            }).join(',')).join('\n');
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
        };


        const [searchQuery, setSearchQuery] = useState("");

        const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(e.target.value);
        };
      
        const filteredData = sales.filter((record) =>
          Object.values(record).some((value) =>
            value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
           
        const handleMailTo = () => {
          if (!currentItem) return;
        
          const recipient = ""; // Replace with the actual recipient email address
          const subject = `Sales Order`;

          // Construct the email body based on the Django template
          const emailBody = `
Dear Team,
            
Please find attached Sales Order.
            
Sales Order Details:
Expected Delivery Date: ${currentItem.expected_delivery_date}
Status: ${currentItem.status}

Sales Order Items:
${currentItem.salesorder_items?.map(item => `
Item: ${item.item.name}
Quantity: ${item.quantity}
            `).join('')}
            
            
Regards,
Sales Manager
          `;
        
          // Create a temporary element to render the email body with HTML
          const tempElement = document.createElement('div');
          tempElement.innerHTML = emailBody;
        
          // Get the innerHTML of the temporary element, which will contain correctly rendered <br> tags
          const formattedEmailBody = tempElement.innerHTML;
        
          const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(formattedEmailBody)}`;
          window.location.href = mailtoLink;
        };

        return (
          <>
          <div className="card">
              <div className="cardHeader">
                  <h2 className="headContent">Sales Orders</h2>
                  <div className="rightContent">
                  <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
{userRole === 'Admin' && (
                  <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
)}
          <Button onClick={downloadCSV}>Download</Button>

                        </div>
                        
              </div>

              <br/>
              <Table className="custom-table"
              dataSource={formatsalesDataProps(filteredData,showUpdateEmployeeDrawer,Closeorder,onChangeAdd,showModal,DeleteOrder)}
              columns={salesordercolumns as any}
              
               
              size="small"
              loading ={fetching}/>

              <EmployeeSelectionDrawer
              id={currentItem?.id}
          visible={updateEmployeeVisible}
          onClose={handleUpdateEmployeeDrawerClose}
          onUpdateEmployee={handleUpdateEmployee} // Pass the callback function
        />
        
        <DetailDrawer
        currentItem={currentItem}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        sendemail={sendemail}
        downloadso={downloadso}
        handleMailTo={handleMailTo}
        onUpdateSuccess={handleUpdateSuccess}
      />
                </div>
          </> 
        )
    }
    
    export default SalesOrderReview