import React, { FC, useState } from "react";
import { Form, Input, Button, notification, Select } from "antd";
import axios, { AxiosResponse } from "axios";
import { subheadersurls } from "../utils/network";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType, headerprops, subheaderprops } from "../utils/types";
import { useGetheaders } from "../utils/hooks";

const { Option } = Select;

const AddSubheaderForm: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState<headerprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  useGetheaders(setHeaders, setFetching);

  const onFinish = async (values: subheaderprops) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      const response: AxiosResponse = await axios.post(subheadersurls + '/', values,headers);
      setLoading(false);
      if (response.status === 201) {
        notification.success({ message: "Subheader added successfully!" });
        onSuccess();
      } else {
        notification.error({ message: "Failed to add subheader." });
      }
    } catch (error) {
      setLoading(false);
      notification.error({ message: "Failed to add subheader." });
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item label="Select Header" name="header_id" rules={[{ required: true, message: 'Please select the header!' }]}>
      <Select placeholder="Select Header">
            {headers.map(headers => (
              <Option key={headers.id} value={headers.id}>{headers.name}</Option>
            ))}
          </Select>

      </Form.Item>
      <Form.Item label="Subheader Name" name="name" rules={[{ required: true, message: 'Please input the subheader name!' }]}>
        <Input placeholder="Subheader Name" />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" loading={loading}>
          Add Subheader
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddSubheaderForm;