import React, { useEffect, useState } from "react";
import { Col, Row, Select, Spin, Table, Tag } from "antd";
import { Link, useParams } from 'react-router-dom';
import backbt from "../assets/images/vr.png"
import pro from "../assets/images/pro.png"
import graph1 from "../assets/images/graph-1.png"
import graph2 from "../assets/images/graph-2.png"
import red from "../assets/images/red.png"
import green from "../assets/images/green.png"
import circle from "../assets/images/circle.png"
import dot from "../assets/images/dot.png"
import nodata from "../assets/images/nodata.png"
import units from "../assets/images/units.png"
import stock from "../assets/images/stock.png"
import weekly from "../assets/images/weekly.png"
import target from "../assets/images/target.png"
import { useGetFranchisee, useGetsummarys } from "../utils/hooks";
import { DashboardData, FranchiseeProps } from "../utils/types";
import Chart from "react-google-charts";
import { getsummarys } from "../utils/functions";
const { Option } = Select;


const SummaryDashboard: React.FC = () => {

  const userRole = localStorage.getItem('token1');

  const [fetching, setFetching] = useState<boolean>(true);

  const formatNumberWithCommas = (number:any) => {
    return number ? number.toLocaleString() : '';
  };
  const [data, setdata] = useState<DashboardData>();

  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };


  const salescolumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },

    {
      title: 'Expected Delivery Date',
      dataIndex: 'formatted_delivery_date',
      key: 'formatted_delivery_date',
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: (customer:any) => <Tag color="#AB67CB">{customer}</Tag>, // Render customer as a Tag
    },
  ];



  const estimatescolumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },

    {
      title: 'Expected Delivery Date',
      dataIndex: 'formatted_completion_date',
      key: 'formatted_completion_date',
    },
    {
      title: 'Lead',
      dataIndex: 'lead_name',
      key: 'lead_name',
      render: (lead:any) => <Tag color="#AB67CB">{lead}</Tag>, // Render customer as a Tag
    },

    
  ];


  const mapMonthToName = (monthIndex:any) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return months[monthIndex - 1]; // Adjust index since months array is 0-indexed
  };
  
  const chartData = [
    ["Month", "Income", "Expense"],
    ...(data?.profit_loss_data.map(item => [
      `${mapMonthToName(item.month)}`, // Convert numeric month to month name
      item.total_income,
      item.total_expense, // Use negative values for expense to display them inside income bars
    ]) || [])
  ];

  const chartOptions = {
    vAxis: { 
      title: 'Amount ($)', 
      gridlines: { count: 0 },
    },
    hAxis: { title: 'Month', gridlines: { count: 0 } }, // Remove horizontal gridlines
    seriesType: 'bars',
    series: { 2: { type: 'line' } },
    colors: ['#AB67CB', '#D4BED9'],
    chartArea: { width: '70%', height: '60%' }, // Adjust chart size
    legend: { position: 'bottom', textStyle: { color: '#290133', fontSize: 10 } }, // Customize legend
  
  };
  useGetsummarys(setdata, setFetching,selectedFranchisee);

  useEffect(() => {
    getsummarys(setdata, setFetching,selectedFranchisee);
  },[selectedFranchisee])


  // Define columns for the Ant Design table

  return (
    <>

      <div className="allnewstructure">
        <Row gutter={{ xs: 10, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <div className="back-div">
              <div className="product-card-header">
                <h2 className="product-headContent">
                </h2>
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
          <Col className="gutter-row" span={24} lg={18} md={12} sm={24} xs={32}>
            <div className="parent-top">
              <div className="product-leftSection1">
                <h3 className="section-title">{data?.franchisee_name}</h3>



                <div className="sku-details">
                  <p className="PrimaryVendor-p1"><span style={{ color: "#290133" as string }}>Products : </span><Tag color="#AB67CB"> {data?.total_products}</Tag></p>
                  <p className="PrimaryVendor-p1"><span style={{ color: "#290133" as string }}>Customers : </span><Tag color="#AB67CB"> {data?.total_customers}</Tag></p>
                  <p className="PrimaryVendor-p1"><span style={{ color: "#290133" as string }}>Suppliers : </span><Tag color="#AB67CB">{data?.total_suppliers}</Tag> </p>
                </div>


                {/* <Table className="custom-tablep" columns={columns} dataSource={data} pagination={false} /> */}
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={24} lg={6} md={12} sm={24} xs={32}>
            <div className="right-data">
              <div className="custom-media">
                <img src={pro} alt="back" className="bk-product-img-1" />
                <div className="media-p">
                {userRole === 'Admin' && (
                <Select
            placeholder="Select Franchisee"
            style={{ width: 200, marginRight: 10 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
                )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mar-30-urgent"></div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24} lg={6} md={12} sm={24} xs={32}>
            <div className="common-div">
              <div className="common-div-left">
                <p className="total-sale-1" style={{ color: "#3182CE" as string }} >Total Sales ($)</p>
                <p className="total-sale-2"><Tag color="#39B2AA">{formatNumberWithCommas(data?.sales_ytd)} YTD</Tag></p>
                <p className="total-sale-3">
                  <span className="green-span">{formatNumberWithCommas(data?.sales_mtd)} MTD</span>
                </p>
              </div>
              <div className="common-div-right">
                <img src={graph1} alt="back" className="width-80" />
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={24} lg={6} md={12} sm={24} xs={32}>
            <div className="common-div">
              <div className="common-div-left">
                <p className="total-sale-1" style={{ color: "#3182CE" as string }}>Total Cost ($)</p>
                <p className="total-sale-2"><Tag color="#39B2AA">{formatNumberWithCommas(data?.costs_ytd)} YTD</Tag></p>
                <p className="total-sale-3">
                  <span >{formatNumberWithCommas(data?.costs_mtd)} MTD</span>
                </p>
              </div>
              <div className="common-div-right">

              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={24} lg={6} md={12} sm={24} xs={32}>
            <div className="common-div">
              <div className="common-div-left">
                <p className="total-sale-1" style={{ color: "#3182CE" as string }}>Profit ($)</p>
                <p className="total-sale-2" ><Tag color="#39B2AA">{formatNumberWithCommas(data?.profit_ytd)} YTD</Tag></p>
                <p className="total-sale-3">
                <span className="green-span">{formatNumberWithCommas(data?.profit_mtd)} MTD</span>

                </p>
              </div>
              <div className="common-div-right">
                <img src={graph2} alt="back" className=" width-60" />
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={24} lg={6} md={12} sm={24} xs={32}>
            <div className="common-div">
              <div className="common-div-left">
                <p className="total-sale-1" style={{ color: "#3182CE" as string }}>Total Inventory</p>
                <p className="total-sale-2" ><Tag color="#39B2AA">{formatNumberWithCommas(data?.inventory_dollars)} $</Tag></p>
                <p className="total-sale-3">
                {formatNumberWithCommas(data?.inventory_units)} Units
                </p>
              </div>
              <div className="common-div-right">

              </div>
            </div>
          </Col>
        </Row>
        <div className="mar-30-urgent"></div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24} lg={12} md={24} sm={32} xs={32}>
            <div className="common-div-1">
            <p className="SalesandForecast">Income and Expenses</p>
<Chart
              chartType="ColumnChart"
              width="100%"
              height="300px"
              data={chartData}
              options={chartOptions}
            />
            </div>
          </Col>
          <Col className="gutter-row" span={24} lg={12} md={24} sm={32} xs={32}>
            <div className="common-div-1">
              <ul className="common-ul-media">
                <img src={circle} alt="back" className="circle-img" />
                <li>
                  <div className="custom-media">
                    <img src={units} alt="back" className="bk-product-img-11"  />
                    <div className="media-p1">
                      <p className="PrimaryVendor-p11">{data?.open_estimates}</p>
                      <p className="PrimaryVendor-p21">Open Estimates</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="custom-media">
                    <img src={weekly} alt="back" className="bk-product-img-11"  />
                    <div className="media-p1">
                      <p className="PrimaryVendor-p11">{data?.open_purchases}</p>
                      <p className="PrimaryVendor-p21">Open Purchases</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="custom-media">
                    <img src={target} alt="back" className="bk-product-img-11" />
                    <div className="media-p1">
                      <p className="PrimaryVendor-p11">{data?.pending_installations}</p>
                      <p className="PrimaryVendor-p21">Pending Installations</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="custom-media">
                    <img src={stock} alt="back" className="bk-product-img-11" />
                    <div className="media-p1">
                      <p className="PrimaryVendor-p11">{data?.total_leads}</p>
                      <p className="PrimaryVendor-p21">Total Leads</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="mar-30-urgent"></div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24} lg={12} md={12} sm={24} xs={32}>
            <div className="common-div-1 h-auto-demo">
              <div className='common-div-1-left' >
                <div className='line-flex'>
                  <div className="common-div-1-left-1">
                    <p className="SalesandForecast">Upcoming Estimates</p>
                  
                  </div>
                  <div className="common-div-1-left-2">
                  <Link className="dot-a" to="/reviewestimates">
                      <img src={dot} alt="back" className="dot-img" />
                    </Link>
                  </div>
                </div>
                <Table
                  className="custom-tablep"
                  columns={estimatescolumns}
                  dataSource={data?.estimates_data}
                  pagination={{pageSize:5}} // Disable pagination if needed
                />

              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={24} lg={12} md={12} sm={24} xs={32}>
            <div className="common-div-1 h-auto-demo">
              <div className='common-div-1-left' >
                <div className='line-flex'>
                  <div className="common-div-1-left-1">
                    <p className="SalesandForecast">Upcoming Installations</p>
                  </div>
                  <div className="common-div-1-left-2">
                  <Link className="dot-a" to="/salesreview">
                      <img src={dot} alt="back" className="dot-img" />
                    </Link>

                  </div>
                </div>
                <Table className="custom-tablep"
                        columns={salescolumns}
                        
                        dataSource={data?.sales_data}
                        pagination={{pageSize:5}} // Disable pagination if needed
                      />
                <div className="custom-tb">
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SummaryDashboard;