import { FC, useState, useEffect } from "react";
import { Button, notification, Input, Form, Select, Table, Spin } from "antd";
import { AxiosResponse } from "axios";
import axios from "axios";
import { AuthTokenType, InventoryProps, location, product} from "../utils/types";
import { useGetLocation, useGetProduct} from "../utils/hooks";
import { getAuthToken } from "../utils/functions";
import { InventoryMD04Url, InventoryUrl } from "../utils/network";

const { Option } = Select;

const InventoryTable: FC = () => {

  const [fetching, setFetching] = useState(false);
  const [inventory, setInventory] = useState<InventoryProps[]>([]);
  const [locations, setLocations] = useState<location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [products, setProducts] = useState<product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  useGetLocation(setLocations, setFetching);
  useGetProduct(setProducts, setFetching);

  useEffect(() => {
    handleGetDetails();
  }, []); // Empty dependency array ensures this effect runs only once when component mounts

  const handleGetDetails = async () => {
    try {
      setFetching(true);
      const headers = getAuthToken() as AuthTokenType;
      let requestData: any = {};

      // Check if any filter is selected, if not, make an API call without filters
      if (selectedLocation || selectedProduct || status) {
        requestData = {
          location_id: selectedLocation,
          product: selectedProduct,
          status: status,
        };
      }

      const response: AxiosResponse<InventoryProps[]> = await axios.post(
        InventoryMD04Url,
        requestData,
        headers
      );
      setInventory(response.data);
    } catch (error) {
      notification.error({
        message: "Operation Error", // Translate the message
      });
    } finally {
      setFetching(false);
    }
  };

  const columns = [
    {
      title: "Material Code Id", // Translate the title
      dataIndex: "material_code",
      key: "material_code",
    },
    {
      title: "Name", // Translate the title
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category", // Translate the title
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Sub Category", // Translate the title
      dataIndex: "sub_category_name",
      key: "sub_category_name",
    },

    {
      title: "Location", // Translate the title
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Status", // Translate the title
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Inventory Quantity", // Translate the title
      dataIndex: "total_units",
      key: "total_units",
    },
  ];

  const downloadCSV = () => {
    // Translate the column titles
    const translatedColumns = columns.map(column => (column.title));
  
    // Convert data to CSV format
    const csvRows = filteredData.map(row =>
      columns.map(column => {
        const dataIndex = column.dataIndex as keyof InventoryProps;
        return `"${row[dataIndex]}"`;
      }).join(",")
    );
  
    const csvContent = [
      translatedColumns.join(","),
      ...csvRows
    ].join("\n");
  
    // Create a Blob object containing the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
  
    // Append the link to the document body and trigger the click event
    document.body.appendChild(link);
    link.click();
  
    // Clean up by revoking the temporary URL
    window.URL.revokeObjectURL(url);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  
  const filteredData = inventory.filter((record) =>
  Object.values(record).some((value) =>
    value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  )
);

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContentn">{("Inventory and Control")}</h2>
        <div className="rightContent">
          <Input
            placeholder="Search..."
            onChange={handleSearch}
            style={{ marginRight: '10px' }}
          />
          <Button
            
            onClick={downloadCSV}
            loading={fetching}
            
          >
            {("Download")}
          </Button>
        </div>
      </div>
      
      <div className="cardContent">
        <Form layout="inline">
          <Form.Item label="" style={{ marginRight: "20px" }}>
            <Select
              defaultValue=""
              style={{ width: "12vw" }}
              onChange={(value: string) => setSelectedLocation(value)}
            >
              <Option value="">{("Select Location")}</Option>
              {locations.map((location, index) => (
                <Option key={index} value={location.id}>
                  {location.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="" style={{ marginRight: "20px" }}>
            <Select
              defaultValue=""
              style={{ width: "12vw" }}
              onChange={(value: string) => setSelectedProduct(value)}
            >
              <Option value="">{("Select Product")}</Option>
              {products.map((product, index) => (
                <Option key={index} value={product.id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="" style={{ marginRight: "20px" }}>
            <Select
              defaultValue=""
              style={{ width: "12vw" }}
              onChange={(value: string) => setStatus(value)}
            >
              <Option value="">{("Select Status")}</Option>
              <Option value="Available">{("Available")}</Option>
              <Option value="Intransit">{("Intransit")}</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handleGetDetails}
              loading={fetching}
           
            >
              {("Get Details")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <br/>
      <Spin spinning={fetching}>
        <Table
          className="custom-table"
          dataSource={filteredData}
          columns={columns}
          pagination={{

            total: filteredData.length, // Total number of items
            showSizeChanger: true, // Option to change page size
            pageSizeOptions: ['10', '50', '100'], // Selectable page sizes
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, // Display range and total items
            // Optionally, you can add other pagination props like current, onChange, etc.
        }}
          bordered
        />
      </Spin>
    </div>
  );
            }
export default InventoryTable;