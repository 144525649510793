import { useState, ChangeEvent, useEffect } from "react"
import {getAuthToken, getestimates} from "../utils/functions"
import {AuthTokenType,FranchiseeProps,estimatesProps, purchaseProps} from "../utils/types"
import { useGetEstimates, useGetFranchisee} from "../utils/hooks"
import { Button,Drawer,Input, Modal, Select, Table, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { onhandUrl, estimatesUrl, estimatesupdateViewurls, estimateformUrl} from "../utils/network"
import jsPDF from 'jspdf';
import FileSaver from 'file-saver'; // Import FileSaver
import EstimateGenerator from "../components/EstimateGenerator"

const { Option } = Select;


const formatestimatesDataProps = (
  estimatesdata: estimatesProps[],
  onlostorder: (estimatesid: estimatesProps) => void,
  onCloseOrder: (estimatesid: estimatesProps) => void,
  onViewDetails: (item: estimatesProps) => void,
  onDeleteOrder: (item: estimatesProps) => void
) => {
  return estimatesdata.map((item) => {
    const confirmAction = (action: () => void, actionName: string) => {
      Modal.confirm({
        title: `Are you sure you want to ${actionName} this estimate?`,
        content: `This action cannot be undone. Please confirm if you wish to ${actionName} the estimate.`,
        onOk: action,
        okText: 'Yes',
        cancelText: 'No',
      });
    };

    if (item.status === "Open") {
      return {
        ...item,
        key: item.id,
        action: (
          <div>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => confirmAction(() => onlostorder(item), "mark as lost")}
            >
              Lost
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => confirmAction(() => onCloseOrder(item), "close")}
            >
              Close
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onViewDetails(item)}
            >
              Details
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => confirmAction(() => onDeleteOrder(item), "delete")}
            >
              Delete
            </span>
          </div>
        ),
      };
    } else {
      return {
        ...item,
        key: item.id,
        action: (
          <div>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => onViewDetails(item)}
            >
              Details
            </span>
          </div>
        ),
      };
    }
  });
};

function EstimatesReview(){
  const userRole = localStorage.getItem('token1');
  const [loading,setLoading]=useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<estimatesProps | null>(null);
 
  const [estimates,setestimates]=useState<estimatesProps[]>([])

  const showModal = (item: estimatesProps) => {
    setCurrentItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

 
  const getColumnFilterValues = (data: estimatesProps[], key: keyof estimatesProps) => {
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
};


      const estimatescolumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            filters: getColumnFilterValues(estimates, "id").map((id) => ({ text: id?.toString(), value: id })),
            onFilter: (value: number | string, record: estimatesProps) => record.id === value,
          },
          {
            title: 'Lead',
            dataIndex: "lead_name",
            key: "lead_name",
            filters: getColumnFilterValues(estimates, "lead_name").map((lead_name) => ({ text: lead_name?.toString(), value: lead_name })),
            onFilter: (value: number | string, record: estimatesProps) => record.lead_name === value,

          },
          
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },

          {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
          },
        ];

        const lostorder= async (estimates:estimatesProps|any)=>{

          const datatosend={
            id:estimates.id,
            status : "Lost",
          }
        
          setLoading(true)
          const headers=getAuthToken() as AuthTokenType
        
          const response:AxiosResponse =await axios.patch(estimatesupdateViewurls+'/'+datatosend.id,datatosend,headers).catch(
              (e)=>{ notification.error({
                  message:"Operation Error",
                  description:e.response?.data.error
              })
        
          }
              ) as AxiosResponse
        
              setLoading(false)
        
              if(response){
                  notification.success({
                      message:"Operation Success",
                      description:"Estimate Marked as Lost"
                      
                  })
        
              }
              getestimates(setestimates, setFetching,selectedFranchisee);       
            }
        
        
        
const Closeorder= async (estimates:estimatesProps|any)=>{

  const datatosend={
    id:estimates.id,
    status : "Closed",
  }

  setLoading(true)
  const headers=getAuthToken() as AuthTokenType

  const response:AxiosResponse =await axios.patch(estimatesupdateViewurls+'/'+datatosend.id,datatosend,headers).catch(
      (e)=>{ notification.error({
          message:"Operation Error",
          description:e.response?.data.error
      })

  }
      ) as AxiosResponse

      setLoading(false)

      if(response){
          notification.success({
              message:"Operation Success",
              description:"Estimate Closed Successfully"
              
          })

      }
      getestimates(setestimates, setFetching,selectedFranchisee);       
    }


    const DeleteOrder= async (estimates:estimatesProps|any)=>{

      const datatosend={
        id:estimates.id,
      }
    
        
      const headers=getAuthToken() as AuthTokenType
    
      const response:AxiosResponse =await axios.delete(estimatesupdateViewurls+'/'+datatosend.id,headers).catch(
          (e)=>{ notification.error({
              message:"Operation Error",
              description:e.response?.data.error
          })
    
      }
          ) as AxiosResponse
    
          setLoading(false)
    
          if(response){
              notification.success({
                  message:"Operation Success",
                  description:"Estimate Deleted Successfully"
                  
              })
    
          }       
          getestimates(setestimates, setFetching,selectedFranchisee);       

        }
    

        const[fetching,setFetching]=useState(true)
          
        const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
        useGetFranchisee(setfranchisee, setFetching);
        const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
        const handleChangeFranchisee = (value: string) => {
          setSelectedFranchisee(value); // Update selected franchisee when filter changes
        };
        useGetEstimates(setestimates,setFetching,selectedFranchisee)
                        
        useEffect(() => {
          getestimates(setestimates, setFetching,selectedFranchisee);
        },[selectedFranchisee])
        
        

        const downloadCSV = () => {
          const csvContent = "data:text/csv;charset=utf-8," +
            estimatescolumns.map(column => column.title).join(',') + '\n' +
            estimates.map(row => estimatescolumns.map(column => {
              return `"${row[column.dataIndex as keyof estimatesProps]}"`;
            }).join(',')).join('\n');
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
        };

        const [searchQuery, setSearchQuery] = useState("");

        const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(e.target.value);
        };
      
        const filteredData = estimates.filter((record) =>
          Object.values(record).some((value) =>
            value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
            

        return (
          <>
          <div className="card">
              <div className="cardHeader">
                  <h2 className="headContent">Estimates</h2>
                  <div className="rightContent">
                  <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
{userRole === 'Admin' && (
                  <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
)}
          <Button onClick={downloadCSV}>Download</Button>

                        </div>
              </div>
              <br/>
              <Table className="custom-table"
              dataSource={formatestimatesDataProps(filteredData,lostorder,Closeorder,showModal,DeleteOrder)}
              columns={estimatescolumns as any}
              
              size="small"
              loading ={fetching}/>
<Drawer
  title="Details"
  visible={isModalVisible}
  placement="right"
  width={400}
  onClose={handleOk}
  style={{ borderRadius: '10px' }} // Add rounded corners to the drawer
>
  {currentItem && (
    <>
      <div style={{ marginBottom: '20px', borderBottom: '1px solid #e8e8e8', paddingBottom: '10px' }}>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>ID: {currentItem.id}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>Serving Location: {currentItem.from_location?.name}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>Lead: {currentItem.lead?.name}</p>
      </div>
      <div>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>Items:</p>
        <Table
          className="custom-table"
          dataSource={currentItem.estimate_items}
          columns={[
            {
              title: 'Item Name',
              dataIndex: ['item', 'name'],
              key: 'name',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              key: 'quantity',
              render: (text: number) => <span>{JSON.stringify(text)}</span>,
            },
          ]}
          pagination={false}
          bordered
          size="small"
          style={{ marginTop: '20px' }}
        />
      </div>
    </>
  )}
  <br/>
  {currentItem && (
    <EstimateGenerator estimate={currentItem} />
  )}

</Drawer>
 
          </div>
          </> 
        )
    }
    
    export default EstimatesReview