import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Select, Input } from "antd";
import { useGetCustomer, useGetFranchisee } from "../utils/hooks";
import { AuthTokenType, FranchiseeProps, customer } from "../utils/types";
import { getAuthToken, getCustomer } from "../utils/functions";
import AddCustomerForm from "../components/AddCustomerForm";
import axios from "axios";
import { customersUrl, customerssalesUrl } from "../utils/network";
const { Option } = Select;

const Customers: FC = () => {

  
  const userRole = localStorage.getItem('token1');


  const getColumnFilterValues = (data: customer[], key: keyof customer) => {
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
  };

  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState<customer | null>(null);

  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };
  const [customers, setCustomers] = useState<customer[]>([]);


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filters: getColumnFilterValues(customers, "id").map((id) => ({ text: id?.toString(), value: id })),
      onFilter: (value: number | string, record: customer) => record.id === value,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filters: getColumnFilterValues(customers, "name").map((name) => ({ text: name, value: name })),
      onFilter: (value: string, record: customer) => record.name === value,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filters: getColumnFilterValues(customers, "email").map((email) => ({ text: email, value: email })),
      onFilter: (value: string, record: customer) => record.email === value,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      filters: getColumnFilterValues(customers, "address").map((address) => ({ text: address, value: address })),
      onFilter: (value: string, record: customer) => record.address === value,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      filters: getColumnFilterValues(customers, "city").map((city) => ({ text: city, value: city })),
      onFilter: (value: string, record: customer) => record.city === value,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      filters: getColumnFilterValues(customers, "state").map((state) => ({ text: state, value: state })),
      onFilter: (value: string, record: customer) => record.state === value,
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
      filters: getColumnFilterValues(customers, "zip").map((zip) => ({ text: zip, value: zip })),
      onFilter: (value: string, record: customer) => record.zip === value,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      filters: getColumnFilterValues(customers, "phone").map((phone) => ({ text: phone, value: phone })),
      onFilter: (value: string, record: customer) => record.phone === value,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: getColumnFilterValues(customers, "type").map((type) => ({ text: type, value: type })),
      onFilter: (value: string, record: customer) => record.type === value,
    },
    {
      title: "Location",
      dataIndex: ["customer_location", "name"],
      key: ["customer_location", "name"],
      filters: getColumnFilterValues(customers, "customer_location").map((location) => ({ text: location, value: location })),
      onFilter: (value: string, record: customer) => record.customer_location?.name === value,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: customer) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];
   useGetCustomer(setCustomers, setFetching, selectedFranchisee);

  useEffect(() => {
    getCustomer(setCustomers, setFetching, selectedFranchisee);
  }, [selectedFranchisee]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = customers.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );



  useEffect(() => {
    // When editingCustomer changes, open the modal
    if (editingCustomer) {
      setDrawerVisible(true);
    }
  }, [editingCustomer]);

  const onCloseWithoutEditing = () => {
    setEditingCustomer(null); // Reset editingCustomer when form is closed without editing
  };

  const handleEdit = (record: customer) => {
    setEditingCustomer(record);
  };

  const handleDelete = (customerId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this customer?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a customer
          await axios.delete(`${customerssalesUrl}/${customerId}/`, headers );

          // After successful deletion, fetch updated customers
          getCustomer(setCustomers, setFetching, selectedFranchisee);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCreateCustomer = () => {
    setDrawerVisible(true);
    setEditingCustomer(null); // Reset editingCustomer for new customer
  };

  const handleExportToCSV = () => {
    // Convert data to CSV format
    const csvContent =
      columns
        .map((column) => column.title)
        .join(",") +
      "\n" +
      customers
        .map((row) =>
          columns
            .map((column) => {
              const key = column.key as keyof customer;
              return row[key] !== undefined ? row[key] : "";
            })
            .join(",")
        )
        .join("\n");

    // Create a Blob object containing the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "customers.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Customers</h2>
        <div className="rightContent">
        <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
            {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
            )}
          <Button onClick={onCreateCustomer}>Add Customer</Button>
          <Button onClick={handleExportToCSV}>Download</Button>
        </div>
      </div>
      <br />
      <Table
        dataSource={filteredData}
        columns={columns as any}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={fetching}
      />
      <AddCustomerForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getCustomer(setCustomers, setFetching, selectedFranchisee);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingCustomer={editingCustomer}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </div>
  );
};

export default Customers;