import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select } from "antd";
import { AuthTokenType, DataProps, AddSupplierFormProps, supplier, location, FranchiseeProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { suppliersUrl, supplierupdatesUrl } from "../utils/network";
import { useGetFranchisee, useGetLocation } from "../utils/hooks";
const { Option } = Select;

const AddSupplierForm: FC<AddSupplierFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingSupplier,
  onCloseWithoutEditing, // new prop

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [locations, setlocations] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  useGetLocation(setlocations, setFetching);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);

  useEffect(() => {
    if (editingSupplier) {
      form.setFieldsValue({...editingSupplier,
        supplier_location_id:editingSupplier.supplier_location?.id,
        franchisee_id: editingSupplier.franchisee.id,

      });
    } else {
      form.resetFields();
    }
  }, [editingSupplier, form]);
  const [hasChanges, setHasChanges] = useState(false);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingSupplier) {
        // Editing supplier
        response = await axios.put(`${supplierupdatesUrl}/${editingSupplier.id}/`, values, headers);
      } else {
        // Adding new supplier
        response = await axios.post(suppliersUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };
  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };


  return (
    <Drawer
      title={editingSupplier ? "Edit Supplier" : "Add Supplier"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the supplier name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input the supplier email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please input the supplier address!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input the supplier city!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please input the supplier state!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input the supplier country!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'Please input the supplier phone number!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: 'Please input the supplier type!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Franchisee"
          name="franchisee_id"
          rules={[{ required: true, message: 'Please select Franchisee!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a Franchisee</Select.Option>
            {franchisee.map(franchisee => (
              <Select.Option key={franchisee.id} value={franchisee.id}>
                {franchisee.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Location"
          name="supplier_location_id"
          rules={[{ required: false, message: 'Please select Location!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a Location</Select.Option>
            {locations.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* Add other form fields for the supplier model */}
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingSupplier ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddSupplierForm;