import React, { useEffect, useState } from "react";
import { Drawer, Row, Col } from "antd";
import axios from "axios";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType } from "../utils/types";
import { estimatephotosUrl } from "../utils/network";

interface EstimateDrawerProps {
  estimateId: number | null;
  visible: boolean;
  onClose: () => void;
}

const EstimatePhotosDrawer: React.FC<EstimateDrawerProps> = ({ estimateId, visible, onClose }) => {
  const [images, setImages] = useState<any[]>([]);

  const fetchImages = async () => {
    try {
      const data=
      { estimate_id: estimateId }
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(
        estimatephotosUrl +'/'+ 'get_images_by_estimate',data,headers  
      );
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    if (visible && estimateId) {
      fetchImages();
    }
  }, [visible, estimateId]);

  return (
    <Drawer
      title={`Images for Estimate ID ${estimateId}`}
      placement="right"
      visible={visible}
      onClose={onClose}
      width="50%"
      height="100%"
    >
      <div style={{ overflowY: "auto", height: "100%" }}>
        <Row gutter={[16, 16]}>
          {images.map((image, index) => (
            <Col key={index} span={24}>
              <div style={{ textAlign: "center", marginBottom: "16px" }}>
                <img
                  src={image.image}
                  alt={`Image ${index}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    objectFit: "cover",
                    cursor: "pointer",
                    transition: "transform 0.2s ease-in-out",
                  }}
                  onClick={() => window.open(image.image, "_blank")}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Drawer>
  );
};

export default EstimatePhotosDrawer;