
import { useEffect, useState} from "react"
import { getAuthToken, getEmail} from "../utils/functions"
import { AuthTokenType, DataProps, EmailProps } from "../utils/types"
import DeleteIcon from '@mui/icons-material/Delete';
import { Button,Modal,  notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { emailsurls} from "../utils/network"
import ContentLayout from "../components/ContentLayout"
import EditIcon from '@mui/icons-material/Edit';
import { EditorState} from 'draft-js';
import {Form} from 'antd'
import { Editor } from 'react-draft-wysiwyg';

import draftToHtml from 'draftjs-to-html';
import {convertFromRaw} from 'draft-js';
import { useGetEmail } from "../utils/hooks";
import AddEmails from "../components/AddEmails";
interface Record {
  [key: string]: any;
}

const formatrecordProps = (
  emails: EmailProps[],
  deleteemails: (record: EmailProps) => void,
  editemails: (record: EmailProps) => void,
) => {
  return emails.map((item) => {
    return {
      ...item,
      key: item.id,
      action: (
        <div>
          
          <span
              style={{color: "blue",cursor: "pointer" }}
              onClick={() => editemails(item)}
            >
              Edit
            </span>{" "}
            |{" "}
            <span
              style={{ color: "blue",cursor: "pointer" }}
              onClick={() => deleteemails(item)}
            >
              Delete
            </span>

        </div>
      ),
    };
  });
};

function Emails(){
      
      const columns = [
        {
          title: 'ID',
          dataIndex: 'key',
          key: 'key',
          render: (text: string) => <b style={{ fontWeight: 600 }}>{text}</b>,

        },
        {
          title: 'Email Text',
          dataIndex: 'emailtext',
          key: 'emailtext',
          render: (text: any) => {
            try {
              const html = draftToHtml(text);
              return <div dangerouslySetInnerHTML={{ __html: html }} />;
            } catch (error) {
              // Handle the error as you see fit
            }
          },
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',

        },
        {
          title: 'Created_At',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (text: string) => <b style={{ fontWeight: 600 }}>{text}</b>,

        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
        },

      ];
    const[modalState,setModalState]=useState(false)
    const[fetching,setFetching]=useState(true)
    const[emails, setemails] = useState<EmailProps[]>([]);
    const [loading,setLoading]=useState(false)
    const [deleteq, setdeleteq] = useState(false);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<Record | null>(null);
    const [form]=Form.useForm();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleEdit = (record:any) => {      
      // Set the current entry
      setCurrentEntry(record);
      // Show the EditEmails component
      setIsEditVisible(true);
      
      
    };

    useEffect(() => {
      if (currentEntry && currentEntry.emailtext) {
        const contentState = convertFromRaw(currentEntry.emailtext);
        setEditorState(EditorState.createWithContent(contentState));


        
      } else {
        setEditorState(EditorState.createEmpty());
      }
    }, [currentEntry]);

    const onSubmitedit=async (values:DataProps)=>{
      
      const datatosend = {
        id: currentEntry ? currentEntry.id : null,
        emailtext: values && values.emailtext ? values.emailtext : (currentEntry ? currentEntry.emailtext : null),
      }
      
      const headers=getAuthToken() as AuthTokenType
      
      const response:AxiosResponse =await axios.patch(emailsurls+'/'+datatosend.id,datatosend,headers).catch(
        (e)=>{ notification.error({
            message:"Operation Error",
            description:e.response?.data.error
        })
  
    }
        ) as AxiosResponse
        getEmail(setemails,setFetching)
        setLoading(false)
        form.resetFields();
        setIsEditVisible(false);
    }
  

      
     

    const deleteemails = (record: EmailProps | any) => {
      Modal.confirm({
        title: 'Are you sure?',
        content: 'Do you want to delete this Question?',
        onOk: async () => {
          const datatosend = {
            id: record.id,
          };
    
          const headers = getAuthToken() as AuthTokenType;
    
          const response: AxiosResponse = await axios
            .delete(emailsurls + '/' + datatosend.id, headers)
            .catch((e) => {
              notification.error({
                message: 'Operation Error',
                description: e.response?.data.error,
              });
            }) as AxiosResponse;
    
          setLoading(false);
    
          if (response) {
            notification.success({
              message: 'Operation Success',
              description: 'Email Deleted Successfully',
            });
            setdeleteq(true);
          }
        },
      });
    };
        useEffect(() => {
          if (deleteq) {
            getEmail(setemails,setFetching)
            
            // Set update back to false
            setdeleteq(false);
          }
        }, [deleteq]);

        
    useGetEmail(setemails,setFetching)

    const onCreateEmails=()=>{
      setModalState(false) 
      setFetching(true)
      getEmail(setemails,setFetching)
    }
     return (
      <div>
      
      <div>
        <ContentLayout
        pageTitle="Followup Emails"
        buttontitle="Add Emails"
        setModalState={setModalState}
        dataSource={formatrecordProps(emails,deleteemails,handleEdit) as any}
        columns={columns}
        fetching={fetching}
        includeFilters={false}
        >
        <AddEmails
        onSuccessCallBack={onCreateEmails} 
        isVisible={modalState}
        onClose={()=>setModalState(false)}/>
        </ContentLayout>
        </div>
        <div>
            <Modal 
          title="Edit Emails" 
          visible={isEditVisible}
          onCancel={() => {
            form.resetFields();
            setIsEditVisible(false);
          }}
          footer={false}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitedit}
            form={form}
          >
            
            <Form.Item name="emailtext" label="Emails">
              <Editor
              key={currentEntry?.id}
              editorState={editorState}
              onEditorStateChange={(newEditorState) => {
                form.setFieldsValue({ emailtext: newEditorState });
                setEditorState(newEditorState);
              }}
                editorClassName="editor"
              />
            </Form.Item>  
            <Form.Item>
              <Button htmlType='submit' type="primary" block loading={loading} >Submit</Button>
            </Form.Item>
          </Form>
        </Modal>
        </div>
        </div>
    )
}
export default Emails