import React, { FC, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import axios, { AxiosResponse } from "axios";
import { headersurls } from "../utils/network";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType, headerprops } from "../utils/types";

const AddHeaderForm: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: headerprops) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      const response: AxiosResponse = await axios.post(headersurls + '/', values,headers);
      setLoading(false);
      if (response.status === 201) {
        notification.success({ message: "Header added successfully!" });
        onSuccess();
      } else {
        notification.error({ message: "Failed to add header." });
      }
    } catch (error) {
      setLoading(false);
      notification.error({ message: "Failed to add header." });
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item label="Header Name" name="name" rules={[{ required: true, message: 'Please input the header name!' }]}>
        <Input placeholder="Header Name" />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" loading={loading}>
          Add Header
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddHeaderForm;
