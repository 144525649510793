import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select, DatePicker, Modal } from "antd";
import axios, { AxiosResponse } from "axios";
import { AddTrasactionFormModalProps, AuthTokenType, DataProps, Transactionprops, headerprops, subheaderprops } from "../utils/types";
import { useGetheaders, useGetsubheaders } from "../utils/hooks";
import AddHeaderForm from "./AddHeaders";
import AddSubheaderForm from "./AddSubheaders";
import { getAuthToken, getheaders, getsubheaders } from "../utils/functions";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import { FaHeading } from "react-icons/fa";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import utc plugin

import AbcIcon from '@mui/icons-material/Abc';
import { transactionsupdateurls, transactionsurls } from "../utils/network";
const { Option } = Select;
dayjs.extend(utc); // Enable utc plugin

const AddTransactionForm: FC<AddTrasactionFormModalProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingTransaction,
  onCloseWithoutEditing
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState<headerprops[]>([]);
  const [subheaders, setSubheaders] = useState<subheaderprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [addHeaderModalVisible, setAddHeaderModalVisible] = useState(false);
  const [addSubheaderModalVisible, setAddSubheaderModalVisible] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const userRole = localStorage.getItem('token1');

  useGetheaders(setHeaders, setFetching);
  useGetsubheaders(setSubheaders, setFetching);

  useEffect(() => {
    if (editingTransaction) {
      form.setFieldsValue({
        ...editingTransaction,
        subheader_id: editingTransaction.subheader.id,
        date: editingTransaction.date ? dayjs(editingTransaction.date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [editingTransaction, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    
    console.log(values)
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingTransaction) {
        response = await axios.put(`${transactionsupdateurls}/${editingTransaction.id}/`,values,headers);
      } else {
        response = await axios.post(transactionsurls + '/', values,headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        notification.success({
          message: "Transaction added successfully!",
        });
        form.resetFields();
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Failed to add transaction.",
      });
    }
  };

  const handleAddHeader = () => {
    setAddHeaderModalVisible(true);
    setSpeedDialOpen(false);
  };

  const handleAddSubheader = () => {
    setAddSubheaderModalVisible(true);
    setSpeedDialOpen(false);
  };

  const handleSpeedDialOpen = () => {
    setSpeedDialOpen(true);
  };

  const handleSpeedDialClose = () => {
    setSpeedDialOpen(false);
  };

  return (
    <Drawer
      title={editingTransaction ? "Edit Transaction" : "Add Transaction"}
      visible={isVisible}
      onClose={onClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label="Subheader" name="subheader_id" rules={[{ required: true, message: 'Please select a subheader!' }]}>
          <Select placeholder="Select Subheader">
            {subheaders.map(subheader => (
              <Option key={subheader.id} value={subheader.id}>{subheader.header.name }-{subheader.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input the Description!' }]}>
        <Input placeholder="Description" />
      </Form.Item>

        <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Please select a date!' }]}>
        <DatePicker
            format="YYYY-MM-DD"
            onChange={(date) => form.setFieldsValue({ date: date ? dayjs(date) : null })} />
        </Form.Item>
        <Form.Item label="Amount" name="amount" rules={[{ required: true, message: 'Please enter the amount!' }]}>
          <Input placeholder="Amount" type="number" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={loading}>
            {editingTransaction ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>

      {/* Add Header Modal */}
      <Modal
        title="Add Header"
        visible={addHeaderModalVisible}
        onCancel={() => setAddHeaderModalVisible(false)}
        footer={null}
      >
        <AddHeaderForm onSuccess={() => {
          setAddHeaderModalVisible(false);
          // Fetch updated headers after adding a new one
          getheaders(setHeaders, setFetching);
        }} />
      </Modal>

      {/* Add Subheader Modal */}
      <Modal
        title="Add Subheader"
        visible={addSubheaderModalVisible}
        onCancel={() => setAddSubheaderModalVisible(false)}
        footer={null}
      >
        <AddSubheaderForm onSuccess={() => {
          setAddSubheaderModalVisible(false);
          // Fetch updated subheaders after adding a new one
          getsubheaders(setSubheaders, setFetching);
        }} />
      </Modal>
      {userRole === 'Admin' && (
      <SpeedDial
        ariaLabel="SpeedDial"
        icon={<AddIcon/>}
        direction="up"
        onClose={handleSpeedDialClose}
        onOpen={handleSpeedDialOpen}
        open={speedDialOpen}
        sx={{ position: 'absolute', bottom: 32, right: 16 }} // Adjusted positioning
        FabProps={{ size: 'small' }} 
      >        <SpeedDialAction
      key="Add Subheader"
      icon={<AbcIcon />}
      tooltipTitle="Add Subheader"
      onClick={handleAddSubheader}
    />

        <SpeedDialAction
          key="Add Header"
          icon={<FaHeading />}
          tooltipTitle="Add Header"
          onClick={handleAddHeader}
        />
      </SpeedDial>
      )}
    </Drawer>
  );
};

export default AddTransactionForm;