import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select } from "antd";
import { AuthTokenType, DataProps, AddLocationFormProps, location, FranchiseeProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { locationsUrl } from "../utils/network";
import { useGetFranchisee } from "../utils/hooks";
const { Option } = Select;

const AddLocationForm: FC<AddLocationFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingLocation,
  onCloseWithoutEditing, // new prop
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  useGetFranchisee(setfranchisee, setFetching);

  useEffect(() => {
    if (editingLocation) {
      form.setFieldsValue({...editingLocation,        
        franchisee_id: editingLocation.franchisee.id,
      });
    } else {
      form.resetFields();
    }
  }, [editingLocation, form]);
  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingLocation) {
        // Editing location
        response = await axios.put(`${locationsUrl}/${editingLocation.id}/`, values, headers);
      } else {
        // Adding new location
        response = await axios.post(locationsUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingLocation ? "Edit Location" : "Add Location"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the location name!' }]}
        >
          <Input placeholder="Location Name" />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: 'Please input the location type!' }]}
        >
          <Input placeholder="Location Type" />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please input the location address!' }]}
        >
          <Input placeholder="Location Address" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input the location city!' }]}
        >
          <Input placeholder="Location City" />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please input the location state!' }]}
        >
          <Input placeholder="Location State" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input the location country!' }]}
        >
          <Input placeholder="Location Country" />
        </Form.Item>
        <Form.Item
          label="Franchisee"
          name="franchisee_id"
          rules={[{ required: true, message: 'Please select Franchisee!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a Franchisee</Select.Option>
            {franchisee.map(franchisee => (
              <Select.Option key={franchisee.id} value={franchisee.id}>
                {franchisee.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingLocation ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddLocationForm;