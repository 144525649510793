import React, { useEffect, useState } from "react";
import { Drawer, Row, Col } from "antd";
import axios from "axios";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType } from "../utils/types";
import { salesorderphotosUrl } from "../utils/network";

interface SalesOrderPhotosDrawerProps {
  salesOrderId: number | null;
  visible: boolean;
  onClose: () => void;
}

const SalesOrderPhotosDrawer: React.FC<SalesOrderPhotosDrawerProps> = ({ salesOrderId, visible, onClose }) => {
  const [images, setImages] = useState<any[]>([]);

  const fetchImages = async () => {
    try {
      const data=
      { salesorder_id: salesOrderId }
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(
        salesorderphotosUrl +'/'+ 'get_images_by_salesorder',data,headers  
      );
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    if (visible && salesOrderId) {
      fetchImages();
    }
  }, [visible, salesOrderId]);

  return (
    <Drawer
      title={`Images for Sales Order ID ${salesOrderId}`}
      placement="right"
      visible={visible}
      onClose={onClose}
      width="50%"
      height="100%"
    >
      <div style={{ overflowY: "auto", height: "100%" }}>
        <Row gutter={[16, 16]}>
          {images.map((image, index) => (
            <Col key={index} span={24}>
              <div style={{ textAlign: "center", marginBottom: "16px" }}>
                <img
                  src={image.image}
                  alt={`Image ${index}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    objectFit: "cover",
                    cursor: "pointer",
                    transition: "transform 0.2s ease-in-out",
                  }}
                  onClick={() => window.open(image.image, "_blank")}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Drawer>
  );
};

export default SalesOrderPhotosDrawer;