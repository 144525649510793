import React, { useEffect, useState } from "react";
import { Table, Input, message, Popconfirm, Button, Select } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { getAuthToken, getpurchase } from "../utils/functions";
import { AuthTokenType,FranchiseeProps,purchaseProps } from "../utils/types";
import { useGetFranchisee, useGetpurchase } from "../utils/hooks";
import { purchaseorderUrl, purchaseorderfilesUrl } from "../utils/network";
import PurchaseOrderFilesDrawer from "../components/PurchaseOrderFilesDrawer";
const { Option } = Select;

const PurchaseOrderfiles = () => {

  const userRole = localStorage.getItem('token1');


  const [fetching, setFetching] = useState(true);
  const [purchaseorder, setpurchaseorder] = useState<purchaseProps[]>([]);
  const [fileLists, setFileLists] = useState<{ [key: number]: any[] }>({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<number | null>(null);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  useGetpurchase(setpurchaseorder, setFetching,selectedFranchisee);

               
  useEffect(() => {
    getpurchase(setpurchaseorder, setFetching,selectedFranchisee);
        },[selectedFranchisee])

  const handleUploadImages = (recordId: number, files: FileList | null) => {
    if (!files) return;
    const newFiles = Array.from(files);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: [...(prevFileLists[recordId] || []), ...newFiles],
    }));
    const fileNames = newFiles.map(file => file.name).join(", ");
    message.success(`Uploaded images for Purchase order ID ${recordId}: ${fileNames}`);
  };

  const handleDeleteImage = (recordId: number, file: any) => {
    const newFileList = fileLists[recordId].filter((f: any) => f !== file);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: newFileList
    }));
    message.success(`Deleted File ${file.name}`);
  };

  const handleSubmit = async (recordId: number) => {
    const headers = getAuthToken() as AuthTokenType;
    try {
      const formData = new FormData();
      formData.append("purchaseorder_id", recordId.toString());
      fileLists[recordId]?.forEach((file: any) => {
        formData.append("files", file);
      });
      await axios.post(purchaseorderfilesUrl, formData, headers );
      message.success("Files submitted successfully");
      setFileLists(prevFileLists => ({
        ...prevFileLists,
        [recordId]: [],
      }));
    } catch (error) {
      console.error("Error submitting files:", error);
      message.error("Failed to submit files");
    }
    getpurchase(setpurchaseorder, setFetching,selectedFranchisee);

  };

  const handleViewFiles = (recordId: number) => {
    setSelectedPurchaseOrderId(recordId);
    setDrawerVisible(true);
  };

  const purchaseOrderColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: 'PO Number',
      dataIndex: 'po_number',
      key: 'po_number',
    },

    {
      title: "Expected Delivery Date",
      dataIndex: "expected_delivery_date",
      key: "expected_delivery_date",
    },
    {
      title: "Actual Delivery Date",
      dataIndex: "actual_delivery_date",
      key: "actual_delivery_date",
    },
    {
      title: 'Supplier',
      dataIndex: ['from_location','name'],
      key: 'from_location',
      filters: purchaseorder.map(item => ({
        text: item.from_location ? item.from_location.name : 'N/A',
        value: item.from_location ? item.from_location.name : 'N/A'
      })),
      onFilter: (value:any, record:any) => record.from_location.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Destination',
      dataIndex: ['to_location','name'],
      key: 'to_location',
      filters: purchaseorder.map(item => ({
        text: item.to_location ? item.to_location.name : 'N/A',
        value: item.to_location ? item.to_location.name : 'N/A'
      })),
      onFilter: (value:any, record:any) => record.to_location.name.toLowerCase().includes(value.toLowerCase()),

    },


    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Uploaded in Progress",
      key: "uploaded_files",
      render: (text: any, record: purchaseProps) => (
        <div>
          {fileLists[record.id]?.map((file: any, index: number) => (
            <span key={index}>
              {file.name}
              <Popconfirm
                title="Are you sure to delete this file?"
                onConfirm={() => handleDeleteImage(record.id, file)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ marginLeft: 8 }} />
              </Popconfirm>
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: purchaseProps) => (
        <>
          <a
            onClick={(e) => document.getElementById(`file-upload-${record.id}`)?.click()}
            style={{ marginRight: 8 }}
          >
            Upload
          </a>
          <input
            id={`file-upload-${record.id}`}
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={(e) => handleUploadImages(record.id, e.target.files)}
          />
          <a onClick={() => handleSubmit(record.id)}>Submit</a>
          {" "}{" "}
          <a onClick={() => handleViewFiles(record.id)}><EyeOutlined /> View</a>
        </>
      ),
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = purchaseorder.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );



  return (
    <>
      <div className="card">
        <div className="cardHeader">
          <h2 className="headContent">Purchase Orders</h2>
          <div className="rightContent">

          <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
 {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
 )}
          </div>
        </div>


        <br />
        <Table
          className="custom-table"
          dataSource={filteredData}
          columns={purchaseOrderColumns}
          size="small"
          loading={fetching}
        />
      </div>
      <PurchaseOrderFilesDrawer
        purchaseorderId={selectedPurchaseOrderId}
        visible={drawerVisible}
        onClose={() => {
          setSelectedPurchaseOrderId(null);
          setDrawerVisible(false);
        }}
      />
    </>
  );
};

export default PurchaseOrderfiles;