import axios,{AxiosResponse} from "axios"
import {tokenName} from "./data"
import {FranchiseeUrl, MeUrl, PricebyLocationUrl, SalesOrderCalendarURL, UsersUrl, appointmentsUrl, categoriesUrl, currenciesUrl, customersUrl, customerssalesUrl, dashboardurls, emailTrackingUrl, emailsurls, estimatesUrl, estimatescalendarUrl, headerprofitlossurls, headersurls, leadsUrl, leadsestimatesUrl, locationsUrl, onhandUrl, productsUrl, profitlossstatementsurls, purchaseorderUrl,salesorderUrl,salesorderwithemployeeUrl,sankeycharturls,stocktransferorderUrl,subheadersurls,suppliersUrl, transactionsurls, unitofmeasuresUrl} from "./network"
import { Appointment, AuthTokenType,DashboardData,EmailProps,EmailTracking,HeaderProfitLossprops,InventoryProps,OnhandInventoryProps,Pricebylocation,ProfitLossStatementprops,Transactionprops,UserType, category, estimatesProps, headerprops, product, purchaseProps, salesProps, stocktransferProps, subheaderprops} from "./types"
import { notification } from "antd"

import moment from "moment"

export const getAuthToken= ():AuthTokenType|null=>{
    const accessToken =localStorage.getItem(tokenName)
    if(!accessToken){
        return null
    }

    return {headers:{Authorization:`Bearer ${accessToken}`}}

}

export const logout =()=>{
    localStorage.removeItem(tokenName)
    window.location.href="/login"

}

export const authHandler=async ():Promise<UserType | null>=>{
    const headers=getAuthToken()
        if(!headers){
            return null
    }
    const response:AxiosResponse =await axios.get(MeUrl,headers).catch(
        (e)=>{}
    ) as AxiosResponse
    if(response){
        return response.data as UserType
    }
    return null

}


export const getFranchisee = async (
  setFranchisee: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(FranchiseeUrl, headers);
    setFranchisee(response.data);
  } finally {
    setFetching(false);
  }
};





export const getUsers = async (
  setUsers: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(UsersUrl, headers);
    setUsers(response.data);
  } finally {
    setFetching(false);
  }
};






export const getCategory = async (
  setCategory: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(categoriesUrl, headers);

    const data=(response.data as category[]).map((item) => ({...item,
      par:item.parent?.name
    
    }))
    
    setCategory(data);
  } finally {
    setFetching(false);
  }
};

export const getUnitofmeasure = async (
  setUnitofmeasure: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(unitofmeasuresUrl, headers);
    setUnitofmeasure(response.data);
  } finally {
    setFetching(false);
  }
};

export const getCurrency = async (
  setCurrency: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(currenciesUrl, headers);
    setCurrency(response.data);
  } finally {
    setFetching(false);
  }
};

export const getLocation = async (
  setLocation: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(locationsUrl, headers);
    setLocation(response.data);
  } finally {
    setFetching(false);
  }
};

export const getCustomer = async (
  setCustomer: (data: any) => void,
  setFetching: (val: boolean) => void,franchisee_id:any
) => {
  const headers = getAuthToken() as AuthTokenType;
  const queryParams = {
    franchisee_id: franchisee_id,
  }
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(customersUrl, {
      ...headers,
         params:queryParams,}
  );
    setCustomer(response.data);
  } finally {
    setFetching(false);
  }
};


export const getCustomersales = async (
  setCustomer: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(customerssalesUrl, headers
  );
    setCustomer(response.data);
  } finally {
    setFetching(false);
  }
};

export const getSupplier = async (
  setSupplier: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(suppliersUrl, headers);
    setSupplier(response.data);
  } finally {
    setFetching(false);
  }
};

export const getProduct = async (
  setProduct: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(productsUrl, headers);
    const data=(response.data as product[]).map((item) => ({...item,
      weight_uom:item.sku_weight_uom.name,
      length_uom:item.sku_length_uom.name,
      height_uom:item.sku_height_uom.name,
      uom:item.sku_uom.name,
      curr:item.currency.name,
      cat:item.category.parent?.name,
      subcat:item.category.name,
      productdropdown:item.name + " " + item.color+ " " + item.sku_height+ "H " + item.sku_length+ "L " 
  
    }))

    setProduct(data);
  } finally {
    setFetching(false);
  }
};

export const getpurchase=async (setpurchase:(data:purchaseProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };

  const response:AxiosResponse =await axios.get(purchaseorderUrl,{
    ...headers,
       params:queryParams,}
).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<purchaseProps>
if(response){
  const data=(response.data as purchaseProps[]).map((item) => ({...item,
    expected_delivery_date:moment(item.expected_delivery_date).format('DD-MM-YYYY'),
    actual_delivery_date:moment(item.actual_delivery_date).format('DD-MM-YYYY'),
    departure_date:moment(item.departure_date).format('DD-MM-YYYY'),
    origin:item.from_location?.name,
    destination:item.to_location?.name
  }))
  setpurchase(data) 
  setFetching(false)

}

}



export const getsales=async (setsales:(data:salesProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };
  const response:AxiosResponse =await axios.get(salesorderwithemployeeUrl,{
    ...headers,
       params:queryParams,}
).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<salesProps>
if(response){
  const data=(response.data as salesProps[]).map((item) => ({...item,
    expected_delivery_date:moment(item.expected_delivery_date).format('DD-MM-YYYY'),
    actual_delivery_date:moment(item.actual_delivery_date).format('DD-MM-YYYY'),
    customer_name:item.customer?.name,
    employee_name:item.employee?.fullname
  }))
  setsales(data)
  
  setFetching(false)

}

}

export const getstocktransfer=async (setstocktransfer:(data:stocktransferProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };
  const response:AxiosResponse =await axios.get(stocktransferorderUrl,{
    ...headers,
       params:queryParams,}
).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<stocktransferProps>
if(response){
  const data=(response.data as stocktransferProps[]).map((item) => ({...item,
    expected_delivery_date:moment(item.expected_delivery_date).format('DD-MM-YYYY'),
    actual_delivery_date:moment(item.actual_delivery_date).format('DD-MM-YYYY'),
    origin:item.from_location?.name,
    destination:item.to_location?.name
  }))
  setstocktransfer(data) 
  setFetching(false)

}

}



export const getOnhandInventory=async (setOnhandInventory:(data:OnhandInventoryProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };
  const response:AxiosResponse =await axios.get(onhandUrl,{
    ...headers,
       params:queryParams,}).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<OnhandInventoryProps>
  const data=(response.data as OnhandInventoryProps[]).map((item) => ({...item,
    created_at:moment(item.created_at).format('DD-MM-YYYY'),
    code:item.sku_code.code,
    name:item.sku_code.name,
    cat:item.sku_code.category.parent.name,
    subcat:item.sku_code.category.name,
    storage:item.storage_location.name,
  }))
  setOnhandInventory(data) 
  setFetching(false)

}


export const getPricebyLocation=async (setPricebyLocation:(data:Pricebylocation[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(PricebyLocationUrl,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<Pricebylocation>
  const data=(response.data as Pricebylocation[]).map((item) => ({...item,
    sku_name:item.product_details.name,
    franchisee_name:item.franchisee.name,
  }))
  setPricebyLocation(data) 
  setFetching(false)
}





export const getLead = async (
  setLead: (data: any) => void,
  setFetching: (val: boolean) => void,
  franchisee_id:any
) => {
  const headers = getAuthToken() as AuthTokenType;
  const queryParams = {
    franchisee_id: franchisee_id,
  };
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(leadsUrl, {
      ...headers,
         params:queryParams,});
    setLead(response.data);
  } finally {
    setFetching(false);
  }
};




export const getLeadestimates = async (
  setLead: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(leadsestimatesUrl,headers);
    
    setLead(response.data);
  } finally {
    setFetching(false);
  }
};




export const getestimates=async (setestimates:(data:estimatesProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };

  const response:AxiosResponse =await axios.get(estimatesUrl,{
    ...headers,
       params:queryParams,}
).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<estimatesProps>
if(response){
  const data=(response.data as estimatesProps[]).map((item) => ({...item,
      lead_name:item.lead?.name,
  
  }))
  setestimates(data) 
  setFetching(false)

}

}

export const getsalescalendar=async (setsales:(data:salesProps[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };
  const response:AxiosResponse =await axios.get(SalesOrderCalendarURL,{
    ...headers,
       params:queryParams,}
).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<salesProps>
if(response){
  const data=(response.data as salesProps[]).map((item) => ({...item,
    title:item.id,
    start:item.expected_delivery_date,
    end:item.expected_delivery_date,
  }))
  setsales(data) 
  setFetching(false)
}

}



export const getAppointments=async (setAppointments:(data:Appointment[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };
  const response:AxiosResponse =await axios.get(appointmentsUrl,{
    ...headers,
       params:queryParams,}).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<Appointment>
if(response){
  const data=(response.data as Appointment[]).map((item) => ({...item,
    lead_name:item.lead?.name,
    location_name:item.from_location?.name,
    employee_name:item.employee?.fullname,
    date1:moment(item.date).format('DD-MM-YYYY'),
  }))
  setAppointments(data) 
  setFetching(false)
}

}






export const getheaders=async (setheaders:(data:headerprops[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(headersurls,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<headerprops>
if(response){
  const data=(response.data as headerprops[]).map((item) => ({...item,
  }))
  setheaders(data) 
  setFetching(false)

}

}


export const getsubheaders=async (setsubheaders:(data:subheaderprops[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(subheadersurls,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<headerprops>
if(response){
  const data=(response.data as subheaderprops[]).map((item) => ({...item,
  }))
  setsubheaders(data) 
  setFetching(false)

}

}

export const gettransactions=async (settransactions:(data:Transactionprops[])=>void,setFetching:(val:boolean)=>void,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id: franchisee_id,
  };

  const response:AxiosResponse =await axios.get(transactionsurls,{
    ...headers,
       params:queryParams,}).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<Transactionprops>
if(response){
  const data=(response.data as Transactionprops[]).map((item) => ({...item,
  }))
  settransactions(data) 
  setFetching(false)

}

}




export const getheaderprofitloss=async (setheaderprofitloss:(data:HeaderProfitLossprops[])=>void,setFetching:(val:boolean)=>void,selectedYear:any,selectedMonth:any,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id:franchisee_id,
    year: selectedYear,
    month: selectedMonth,

  };
  const response:AxiosResponse =await axios.get(headerprofitlossurls,{
    ...headers,
       params:queryParams,}).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<HeaderProfitLossprops>
if(response){
  const data=(response.data as HeaderProfitLossprops[]).map((item) => ({...item,
  }))
  setheaderprofitloss(data) 
  setFetching(false)

}
}

export const getprofitloss=async (setprofitloss:(data:ProfitLossStatementprops[])=>void,setFetching:(val:boolean)=>void,selectedYear:any,selectedMonth:any,franchisee_id:any)=>{
  const headers=getAuthToken() as AuthTokenType
  const queryParams = {
    franchisee_id:franchisee_id,
    year: selectedYear,
    month: selectedMonth,

  };
  const response:AxiosResponse =await axios.get(profitlossstatementsurls,{
    ...headers,
       params:queryParams,}).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<ProfitLossStatementprops>
if(response){
  const data=(response.data as ProfitLossStatementprops[]).map((item) => ({...item,
  }))
  setprofitloss(data) 
  setFetching(false)

}
}


export const getSankey = async (
  setSankey: (data: any) => void,
  setFetching: (val: boolean) => void,month:any, year:any, franchisee:any
) => {
  const headers = getAuthToken() as AuthTokenType;
  const queryParams = {
    franchisee_id:franchisee,
    year: year,
    month: month,

  };
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(sankeycharturls, {
      ...headers,
         params:queryParams,});
    setSankey(response.data);
  } finally {
    setFetching(false);
  }
};



export const getsummarys = async (
  setsummarys: (data: DashboardData) => void,
  setFetching: (val: boolean) => void,franchisee:any
) => {
  const headers = getAuthToken() as AuthTokenType;
  const queryParams = {
    franchisee_id:franchisee,
  };
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(dashboardurls,{
      ...headers,
         params:queryParams,});
    setsummarys(response.data);
  } finally {
    setFetching(false);
  }
};



export const getEmail=async (setEmail:(data:EmailProps[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(emailsurls,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<EmailProps>
if(response){
  
  const data=(response.data as EmailProps[]).map((item) => ({...item,key:item.id,
      created_at:moment(item.created_at).format('DD-MM-YYYY'),
  }))

  setEmail(data)
  setFetching(false)
}
}


export const getEmailTracking = async (setEmailTrackings: (data: EmailTracking[]) => void, setFetching: (fetching: boolean) => void) => {
  setFetching(true);
  try {
    const headers = getAuthToken()  as AuthTokenType;
    const response:AxiosResponse = await axios.get(emailTrackingUrl,headers );
    const data=(response.data as EmailTracking[]).map((item) => ({...item,
      firstmaildate1:moment(item.firstmaildate).format('DD-MM-YYYY'),
      secondmaildate1:moment(item.secondmaildate).format('DD-MM-YYYY'),
      thirdmaildate1:moment(item.thirdmaildate).format('DD-MM-YYYY'),
    }))
    setEmailTrackings(data);
  } catch (error) {
    // handle error
  } finally {
    setFetching(false);
  }
};