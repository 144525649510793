import { useState, ChangeEvent, useEffect } from "react"
import {getAuthToken, getpurchase} from "../utils/functions"
import {AuthTokenType,FranchiseeProps,purchaseProps} from "../utils/types"
import { useGetFranchisee, useGetpurchase} from "../utils/hooks"
import { Button,Drawer,Input, Modal, Table, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { onhandUrl, purchaseorderUrl, purchaseorderupdatesUrl, sendpoUrl, viewpoUrl} from "../utils/network"
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import FileSaver from "file-saver"; // Import FileSaver.js library

import {Select} from 'antd'
import { Link } from "react-router-dom"
import SalesOrderPhotosDrawer from "../components/SalesOrderPhotoDrawer"
const { Option } = Select;


const formatpurchaseDataProps = (
  purchasedata: purchaseProps[],
  onCloseOrder: (purchaseid: purchaseProps) => void,
  onChangeAdd: (value: Date | null, purchase_id: number) => void,
  onViewDetails: (item: purchaseProps) => void,
  onDeleteOrder: (item: purchaseProps) => void,
  onOpenDrawer: (item: purchaseProps) => void
) => {
  return purchasedata.map((item) => {
    let actionLabel = '';
    let actionHandler = () => {};

    if (item.status === 'Open') {
      actionLabel = 'Intransit';
      actionHandler = () => onCloseOrder(item);
    } else if (item.status === 'Intransit') {
      actionLabel = 'Close';
      actionHandler = () => onCloseOrder(item);
    }

    const changeDeliveryDateInput = item.status === 'Intransit' ? (
      <div>
        <Input
          style={{ width: "150px" }}
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeAdd(e.target.valueAsDate, item.id)
          }
        />
      </div>
    ) : null;

    return {
      ...item,
      key: item.id,
      change_delivery_date: changeDeliveryDateInput,
      action: (
        <div>
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={actionHandler}
          >
            {actionLabel}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onViewDetails(item)}
          >
            Details
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onDeleteOrder(item)}
          >
            Delete
          </span>
          {" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onOpenDrawer(item)} // Add button to open the drawer
          >
            View Photos
          </span>
        </div>
      ),
    };
  });
};

function PurchaseOrderReview(){
  const userRole = localStorage.getItem('token1');

  const[modalState,setModalState]=useState(false)
  const[fetching,setFetching]=useState(true)
    
  const [purchase,setpurchase]=useState<purchaseProps[]>([])
  const [purchaseadd,setpurchaseadd]=useState<{[key:number]:Date|null}>([])
         
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };
  useGetpurchase(setpurchase,setFetching,selectedFranchisee)
  const [loading,setLoading]=useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<purchaseProps | null>(null);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [pdfData, setPdfData] = useState<any>(null);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState<number | null>(null);

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  
  const handleOpenDrawer = (purchaseId: purchaseProps) => {
    console.log(purchaseId)
    const salesOrderId = purchaseId.sales_order_reference?.id ?? null;
    setSelectedSalesOrderId(salesOrderId);
    setDrawerVisible(true);
  };


  const showModal = (item: purchaseProps) => {
    setCurrentItem(item);
    setIsModalVisible(true);
  };


  useEffect(() => {
    getpurchase(setpurchase, setFetching,selectedFranchisee);
        },[selectedFranchisee])



  const handleOk = () => {
    setIsModalVisible(false);
  };



  const sendemail= async (purchaseorder:purchaseProps|any)=>{

    const datatosend={
      purchase_order_id:purchaseorder.id,
    }
  
      const headers=getAuthToken() as AuthTokenType
      
  
    const response:AxiosResponse =await axios.post(sendpoUrl,datatosend,headers).catch(
        (e)=>{ notification.error({
            message:"Operation Error",
            description:e.response?.data.error
        })
  
    }
        ) as AxiosResponse
  
        setLoading(false)
  
        if(response){
            notification.success({
                message:"Operation Success",
                description:"Email Sent Successfully"
                
            })
  
        }
      }

      const downloadpo= async (purchaseorder:purchaseProps|any)=>{

        const datatosend={
          purchase_order_id:purchaseorder.id,
        }
      
          const headers=getAuthToken() as AuthTokenType
          
      
        const response:AxiosResponse =await axios.post(viewpoUrl,datatosend,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.error
            })
      
        }
            ) as AxiosResponse
          const pdfBlob = new Blob([response.data], { type: "application/pdf" });
          FileSaver.saveAs(pdfBlob, `Purchase_Order_${datatosend.purchase_order_id}.pdf`);
                     setLoading(false)
      
          }
    




      const purchaseordercolumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'PO Number',
            dataIndex: 'po_number',
            key: 'po_number',
          },
          {
            title: 'Supplier',
            dataIndex: 'origin',
            key: 'origin',
            filters: purchase.map(item => ({
              text: item.from_location ? item.from_location.name : 'N/A',
              value: item.from_location ? item.from_location.name : 'N/A'
            })),
            onFilter: (value:any, record:any) => record.from_location.name.toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination',
            filters: purchase.map(item => ({
              text: item.to_location ? item.to_location.name : 'N/A',
              value: item.to_location ? item.to_location.name : 'N/A'
            })),
            onFilter: (value:any, record:any) => record.to_location.name.toLowerCase().includes(value.toLowerCase()),

          },

          {
            title: 'Expected Delivery Date',
            dataIndex: 'expected_delivery_date',
            key: 'expected_delivery_date',
          },
          {
            title: 'Actual Delivery Date',
            dataIndex: 'actual_delivery_date',
            key: 'actual_delivery_date',
          },

          {
            title: 'Free Days',
            dataIndex: 'free_days',
            key: 'free_days',
          },
          {
            title: 'Internal Reference',
            dataIndex: 'internal_reference',
            key: 'internal_reference',
          },
          {
            title: 'Origin Country',
            dataIndex: 'origin_country',
            key: 'origin_country',
          },
          {
            title: 'Origin Port',
            dataIndex: 'origin_port',
            key: 'origin_port',
          },

          {
            title: 'Destination Port',
            dataIndex: 'destination_port',
            key: 'destination_port',
          },
          {
            title: 'Container Id',
            dataIndex: 'container_id',
            key: 'container_id',
          },
          {
            title: 'Portfolio',
            dataIndex: 'portfolio',
            key: 'portfolio',
          },
          {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
          },
          {
            title: 'Shipping Company',
            dataIndex: 'shipping_company',
            key: 'shipping_company',
          },
          {
            title: 'DIN Number',
            dataIndex: 'din_number',
            key: 'din_number',
          },


          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: 'Change Delivery Date',
            dataIndex: 'change_delivery_date',
            key: 'change_delivery_date',
          },

          {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
          },
        ];

        
        const Closeorder = async (purchaseorder: purchaseProps | any) => {
          let statusToUpdate = '';
        
          if (purchaseorder.status === 'Open') {
            statusToUpdate = 'Intransit';
            for (let i = 0; i < purchaseorder.purchaseorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                storage_location_id: purchaseorder.to_location.id,
                status: "Intransit",
                totalunits: purchaseorder.purchaseorder_items[i].quantity
              };
              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
            }
          } else if (purchaseorder.status === 'Intransit') {
            statusToUpdate = 'Closed';
            // Update inventory only if transitioning from 'Intransit' to 'Closed'
            for (let i = 0; i < purchaseorder.purchaseorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                storage_location_id: purchaseorder.to_location.id,
                status: "Available",
                totalunits: purchaseorder.purchaseorder_items[i].quantity
              };
              const datatosend2inv2 = {
                sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                storage_location_id: purchaseorder.to_location.id,
                status: "Intransit",
                totalunits: -purchaseorder.purchaseorder_items[i].quantity
              };
              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
              await axios.post(onhandUrl, datatosend2inv2, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
              
            }
          }
        
          const datatosend: any = {
            id: purchaseorder.id,
            status: statusToUpdate,
          };
        
          if (statusToUpdate === 'Closed') {
            datatosend.actual_delivery_date = purchaseadd[purchaseorder.id];
          }
        
          setLoading(true);
          const headers = getAuthToken() as AuthTokenType;
        
          const response: AxiosResponse = await axios.patch(purchaseorderupdatesUrl + '/' + datatosend.id, datatosend, headers).catch((e) => {
            notification.error({
              message: "Operation Error",
              description: e.response?.data.error
            });
          }) as AxiosResponse;
        
          setLoading(false);
        
          if (response) {
            notification.success({
              message: "Operation Success",
              description: "Order " + (statusToUpdate === 'Closed' ? "Closed" : "Updated to Intransit") + " Successfully"
            });
          }
        
          getpurchase(setpurchase, setFetching,selectedFranchisee);
        };
        

    const DeleteOrder= async (purchaseorder:purchaseProps|any)=>{
      const confirmDeletion = () => {
        Modal.confirm({
          title: ("Confirm Deletion"),
          content: ("Are you sure you want to delete this order?"),
          onOk: async () => {
            try {
 
      const datatosend={
        id:purchaseorder.id,
      }
    
        
      const headers=getAuthToken() as AuthTokenType
    
      const response:AxiosResponse =await axios.delete(purchaseorderUrl+'/'+datatosend.id,headers).catch(
          (e)=>{ notification.error({
              message:"Operation Error",
              description:e.response?.data.error
          })
    
      }
          ) as AxiosResponse
    
          setLoading(false)
    
          if(response){
              notification.success({
                  message:"Operation Success",
                  description:"Order Deleted Successfully"
                  
              })
    
          }       
          getpurchase(setpurchase, setFetching,selectedFranchisee);       

        } catch (error) {
          notification.error({
            message: ("Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: ("Yes"),
      cancelText: ("No"),
    });
  };

  confirmDeletion();
};

    
             
  const onChangeAdd=(value:Date|null,purchase_id:number)=>{
          setpurchaseadd({
            ...purchaseadd,
            [purchase_id]:value
          })

        }
        
        const downloadCSV = () => {
          const csvContent = "data:text/csv;charset=utf-8," +
            purchaseordercolumns.map(column => column.title).join(',') + '\n' +
            purchase.map(row => purchaseordercolumns.map(column => {
              return `"${row[column.dataIndex as keyof purchaseProps]}"`;
            }).join(',')).join('\n');
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
        };
        const [searchQuery, setSearchQuery] = useState("");
 


        return (
          <>
          <div className="card">
              <div className="cardHeader">
                  <h2 className="headContent">Purchase Orders</h2>
                  <div className="rightContent">
                  <Input
              placeholder="Search..."
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              style={{ marginRight: '10px' }}
            />
             {userRole === 'Admin' && (
                      <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>

          )}
            <Button onClick={downloadCSV}>Download</Button>
                        </div>
              </div>
              <br/>
              <Table className="custom-table"
                            dataSource={(formatpurchaseDataProps(purchase,Closeorder,onChangeAdd,showModal,DeleteOrder,handleOpenDrawer)).filter(record =>
                              Object.values(record).some(value =>
                                value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                              ))}
                columns={purchaseordercolumns}
              
               
              size="small"
              loading ={fetching}/>
<Drawer
  title="Details"
  visible={isModalVisible}
  placement="right"
  onClose={handleOk}
  style={{ borderRadius: '10px' }} // Add rounded corners to the drawer
>
  {currentItem && (
    <>
      <div style={{ marginBottom: '20px', borderBottom: '1px solid #e8e8e8', paddingBottom: '10px' }}>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>ID: {currentItem.id}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>Supplier: {currentItem.from_location?.name}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>To Location: {currentItem.to_location?.name}</p>
      </div>
      <div>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>Items:</p>
        <Table
          className="custom-table"
          dataSource={currentItem.purchaseorder_items}
          columns={[
            {
              title: 'Item Name',
              dataIndex: ['item', 'name'],
              key: 'name',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              key: 'quantity',
              render: (text: number) => <span>{JSON.stringify(text)}</span>,
            },
          ]}
          pagination={false}
          bordered
          size="small"
          style={{ marginTop: '20px' }}
        />
      </div>
    </>
  )}
   <SpeedDial
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            onClose={() => setSpeedDialOpen(false)}
            onOpen={() => setSpeedDialOpen(true)}
            open={speedDialOpen}
            direction="up"
            FabProps={{ size: 'small' }} 
          >
  <SpeedDialAction
    key="Send Email"
    icon={<EmailIcon />}
    tooltipTitle="Send Email"
    onClick={() => sendemail(currentItem)}
  />
  <SpeedDialAction
    key="Download"
    icon={<PictureAsPdfIcon />}
    tooltipTitle="Download "
    onClick={() => downloadpo(currentItem)}
  />
  
          </SpeedDial>
          
</Drawer>
          </div>
          <SalesOrderPhotosDrawer
        visible={drawerVisible}
        onClose={handleCloseDrawer}
        salesOrderId={selectedSalesOrderId}
      />
          </> 
        )
    }
    
    export default PurchaseOrderReview