import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { getSankey } from "../../utils/functions";

export const options = {
  sankey: {
    node: {
      colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
      label: {
        fontSize: 14,
        color: '#333333',
        bold: true,
        italic: false,
      },
    },
    link: {
      colorMode: 'gradient',
      colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
    },
  },
  tooltip: {
    isHtml: true,
    textStyle: {
      color: '#000000',
      fontSize: 12,
    },
  },
  backgroundColor: '#ffffff',
};

interface SankeychartProps {
  month: number | string;
  year: number | string;
  franchisee: string | null;
}

const Sankeychart: React.FC<SankeychartProps> = ({ month, year, franchisee }) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    getSankey(setData, setFetching, month, year, franchisee);
  }, [month, year, franchisee]);

  if (fetching) {
    return <div>Loading...</div>;
  }

  if (data.length <= 1) {
    return <div>No Data</div>;
  }

  return (
    <div style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
      <div className="headContent" style={{ textAlign: 'center', marginBottom: '20px' }}>
      </div>
      <Chart
        chartType="Sankey"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </div>
  );
};

export default Sankeychart;