import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Switch } from "antd";
import { AuthTokenType, DataProps, unitofmeasure, AddUnitofmeasureFormProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { unitofmeasuresUrl } from "../utils/network";

const AddUnitofmeasureForm: FC<AddUnitofmeasureFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingUnitofmeasure,
  onCloseWithoutEditing, // new prop

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editingUnitofmeasure) {
      form.setFieldsValue({
        ...editingUnitofmeasure,
        status: editingUnitofmeasure.is_active === true, // Convert to boolean
      });
    } else {
      form.resetFields();
    }
  }, [editingUnitofmeasure, form]);
  const [hasChanges, setHasChanges] = useState(false);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingUnitofmeasure) {
        // Editing unit of measure
        response = await axios.put(`${unitofmeasuresUrl}/${editingUnitofmeasure.id}/`, values, headers);
      } else {
        // Adding new unit of measure
        
        response = await axios.post(unitofmeasuresUrl + '/', values, headers);
        
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingUnitofmeasure ? "Edit Unit of Measure" : "Add Unit of Measure"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the unit of measure name!' }]}
        >
          <Input placeholder="Unit of Measure Name" />
        </Form.Item>
        <Form.Item
          label="Is Active"
          name="is_active"
          valuePropName="checked"
        >

          <Switch />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingUnitofmeasure ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddUnitofmeasureForm;