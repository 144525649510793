import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag } from "antd";
import { useGetEmailTracking } from "../utils/hooks";
import { AuthTokenType, EmailTracking } from "../utils/types";
import { getAuthToken, getEmailTracking } from "../utils/functions";
import axios from "axios";
import { emailTrackingUrl, emailupdateTrackingUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import AddEmailTrackingForm from "../components/AddEmailtrackingForm";

const EmailTrackings: FC = () => {
  const [emailTrackings, setEmailTrackings] = useState<EmailTracking[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingEmailTracking, setEditingEmailTracking] = useState<EmailTracking | null>(null);

  useGetEmailTracking(setEmailTrackings, setFetching);

  useEffect(() => {
    if (editingEmailTracking) {
      setDrawerVisible(true);
    }
  }, [editingEmailTracking]);

  const onCloseWithoutEditing = () => {
    setEditingEmailTracking(null);
  };

  const handleEdit = (record: EmailTracking) => {
    setEditingEmailTracking(record);
  };

  const handleDelete = (emailTrackingId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this email tracking record?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${emailupdateTrackingUrl}/${emailTrackingId}`, headers);
          getEmailTracking(setEmailTrackings, setFetching);
        } catch (error) {
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCreateEmailTracking = () => {
    setDrawerVisible(true);
    setEditingEmailTracking(null);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Estimate ID",
      dataIndex: ["estimate","id"],
      key: ["estimate","id"],
    },
    {
      title: "Lead Name",
      dataIndex: ["estimate","lead","name"],
      key: ["estimate","lead","name"],
    },
    {
      title: "First Mail Date",
      dataIndex: "firstmaildate1",
      key: "firstmaildate1",
    },
    {
      title: "First Mail Flag",
      dataIndex: "firstmailflag",
      key: "firstmailflag",
      render: (flag: boolean) => (
        <Tag color={flag ? "green" : "red"}>
          {flag ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "First Mail Status",
      dataIndex: "firstmailstatus",
      key: "firstmailstatus",
    },
    {
      title: "Second Mail Date",
      dataIndex: "secondmaildate1",
      key: "secondmaildate1",
    },
    {
      title: "Second Mail Flag",
      dataIndex: "secondmailflag",
      key: "secondmailflag",
      render: (flag: boolean) => (
        <Tag color={flag ? "green" : "red"}>
          {flag ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Second Mail Status",
      dataIndex: "secondmailstatus",
      key: "secondmailstatus",
    },
    {
      title: "Third Mail Date",
      dataIndex: "thirdmaildate1",
      key: "thirdmaildate1",
    },
    {
      title: "Third Mail Flag",
      dataIndex: "thirdmailflag",
      key: "thirdmailflag",
      render: (flag: boolean) => (
        <Tag color={flag ? "green" : "red"}>
          {flag ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Third Mail Status",
      dataIndex: "thirdmailstatus",
      key: "thirdmailstatus",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: EmailTracking) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  return (
    <ContentLayout
      pageTitle="Email Trackings"

      setModalState={setDrawerVisible}
      dataSource={emailTrackings as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
      <AddEmailTrackingForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getEmailTracking(setEmailTrackings, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingEmailTracking={editingEmailTracking}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default EmailTrackings;