import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Switch, DatePicker } from "antd";
import { AuthTokenType,AddEmailTrackingFormProps } from "../utils/types";
import { getAuthToken} from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { emailTrackingUrl, emailupdateTrackingUrl } from "../utils/network";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import utc plugin
dayjs.extend(utc); // Enable utc plugin

const AddEmailTrackingForm: FC<AddEmailTrackingFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  onCloseWithoutEditing,
  editingEmailTracking,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editingEmailTracking) {
      form.setFieldsValue({
        ...editingEmailTracking,
        firstmaildate: editingEmailTracking.firstmaildate ? dayjs(editingEmailTracking.firstmaildate) : null,
        secondmaildate: editingEmailTracking.secondmaildate ? dayjs(editingEmailTracking.secondmaildate) : null,
        thirdmaildate: editingEmailTracking.thirdmaildate ? dayjs(editingEmailTracking.thirdmaildate) : null,
      });
    } else {
      form.resetFields();
    }
  }, [editingEmailTracking, form]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;


      if (editingEmailTracking) {
        response = await axios.patch(`${emailupdateTrackingUrl}/${editingEmailTracking.id}`, values, headers);
      } else {
        response = await axios.post(emailTrackingUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        form.resetFields();
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.();
    }
  };

  return (
    <Drawer
      title={editingEmailTracking ? "Edit Email Tracking" : "Add Email Tracking"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label="First Mail Flag" name="firstmailflag" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Second Mail Flag" name="secondmailflag" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Third Mail Flag" name="thirdmailflag" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="First Email Date" name="firstmaildate" rules={[{ required: false, message: 'Please select a date!' }]}>
        <DatePicker
            format="YYYY-MM-DD"
            onChange={(firstmaildate) => form.setFieldsValue({ firstmaildate: firstmaildate ? dayjs(firstmaildate) : null })} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingEmailTracking ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddEmailTrackingForm;