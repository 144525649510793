import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Modal, Select, notification } from "antd"
import {FC, useState} from "react"
import { AddEmailProps, AuthTokenType, DataProps} from "../utils/types"
import {Form,Button} from 'antd'
import { getAuthToken } from "../utils/functions"
import axios,{AxiosResponse} from "axios"
import { Editor } from 'react-draft-wysiwyg';
import { emailsurls } from '../utils/network';
const { Option } = Select;

const AddEmails:FC<AddEmailProps>=({
    isVisible=false,
    onSuccessCallBack,
    onClose})=>{

    const [form]=Form.useForm();
    const [loading,setLoading]=useState(false)

         
    const onSubmit=async (values:DataProps)=>{
        setLoading(true)
        const headers=getAuthToken() as AuthTokenType


        const response:AxiosResponse =await axios.post(emailsurls,values,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.detail
            })
            
            form.resetFields()
        }
            ) as AxiosResponse
            setLoading(false)

            if(response){
                notification.success({
                    message:"Operation Success",
                    description:"Email Created Successfully"
                })
                onSuccessCallBack()
                form.resetFields()

            } 
        return null

        }
        return <Modal 
            title="Add Email" 
            visible={isVisible}
            onCancel= {() => {
                form.resetFields();
                onClose();
            }}
            footer={false}>
            <Form
                    layout="vertical"
                    onFinish={onSubmit}
                    form={form}
                 
                >
                      <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select a type' }]}>
                    <Select placeholder="Select a type">
                        <Option value="Sales">Sales</Option>
                        <Option value="FirstEmail">First Email</Option>
                        <Option value="SecondEmail">Second Email</Option>
                        <Option value="ThirdEmail">Third Email</Option>
                    </Select>
                </Form.Item>
                    <Form.Item name="emailtext" label="Email">
                    <Editor
                    onEditorStateChange={(value:any) => form.setFieldsValue({ email: value })}
                    editorClassName="editor"
                    />
        </Form.Item>  
        <Form.Item>
                        <Button htmlType='submit' type="primary" block loading={loading} >Submit</Button>
                    </Form.Item>
                </Form>
      </Modal>

    }
export default AddEmails