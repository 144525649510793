import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag } from "antd";
import { useGetCurrency } from "../utils/hooks";
import { AuthTokenType, currency } from "../utils/types";
import { getAuthToken, getCurrency } from "../utils/functions";
import AddCurrencyForm from "../components/AddCurrencyForm";
import axios from "axios";
import { currenciesUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";

const Currencies: FC = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: currency) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [currency, setCurrency] = useState<currency[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCurrency, setEditingCurrency] = useState<currency | null>(null);

  useGetCurrency(setCurrency, setFetching);
  const onCloseWithoutEditing = () => {
    setEditingCurrency(null); // Reset editingCategory when form is closed without editing
  };

  useEffect(() => {
    // When editingCurrency changes, open the modal
    if (editingCurrency) {
      setDrawerVisible(true);
    }
  }, [editingCurrency]);

  const handleEdit = (record: currency) => {
    setEditingCurrency(record);
  };

  const handleDelete = (currencyId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this currency?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a currency
          await axios.delete(`${currenciesUrl}/${currencyId}/`, headers);

          // After successful deletion, fetch updated currencies
          getCurrency(setCurrency, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCreateCurrency = () => {
    setDrawerVisible(true);
    setEditingCurrency(null); // Reset editingCurrency for new currency
  };

  return (
    <ContentLayout
      pageTitle="Currencies"
      buttontitle="Add Currency"
      setModalState={setDrawerVisible}
      dataSource={currency as any} 
      columns={columns as any}
      fetching={fetching}
      includeFilters={true} 

    >
      <AddCurrencyForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getCurrency(setCurrency, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingCurrency={editingCurrency}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Currencies;