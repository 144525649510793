import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag, Input, Select } from "antd";
import { useGetAppointments, useGetCategory, useGetFranchisee } from "../utils/hooks";
import { Appointment, AuthTokenType, FranchiseeProps, category } from "../utils/types";
import { getAppointments, getAuthToken, getCategory } from "../utils/functions";
import axios from "axios";
import { appointmentsUrl, appointmentsupdateurls } from "../utils/network";
import AddAppointmentForm from "../components/AddAppointments";
import AppointmentsCalendar from "./AppointmentsCalendar";
const { Option } = Select;

const Appointments: FC = () => {
  const [filterValues, setFilterValues] = useState<{ [key: string]: string[] }>({});
  const [calendarVisible, setCalendarVisible] = useState<boolean>(false); // State to control modal visibility  
  const userRole = localStorage.getItem('token1');

  
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },

    {
      title: "Lead",
      dataIndex: "lead_name",
      key: "lead_name",
    },
    {
      title: "Date",
      dataIndex: "date1",
      key: "date1",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: Appointment) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [Appointments, setAppointments] = useState<Appointment[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingAppointment, seteditingAppointment] = useState<Appointment | null>(null);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };


  useGetAppointments(setAppointments, setFetching,selectedFranchisee);
  
  useEffect(() => {
    getAppointments(setAppointments, setFetching,selectedFranchisee);
  },[selectedFranchisee])


  
  useEffect(() => {
    // When editingCategory changes, open the modal
    if (editingAppointment) {
      setDrawerVisible(true);
    }
  }, [editingAppointment]);

  const onCloseWithoutEditing = () => {
    seteditingAppointment(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: Appointment) => {
    seteditingAppointment(record);
  };
  const handleDelete = (AppointmentId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this Appointment?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a category
          await axios.delete(`${appointmentsupdateurls}/${AppointmentId}`, headers);
  
          // After successful deletion, fetch updated Appointments
          getAppointments(setAppointments, setFetching,selectedFranchisee);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };


  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Appointments</h2>
        <div className="rightContent">
        <Input placeholder="Search..." style={{ marginRight: 10 }} />
        {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>)}


          <Button onClick={() => setDrawerVisible(true)}>Add Appointment</Button>
        </div>
      </div>
      <br />
      <Table
        className="custom-table"
        dataSource={Appointments}
        columns={columns}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={fetching}
      />
      <AddAppointmentForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getAppointments(setAppointments, setFetching,selectedFranchisee);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingAppointment={editingAppointment}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </div>
  );
};

export default Appointments;
