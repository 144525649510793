import { useState, ChangeEvent, useEffect } from "react"
import {getAuthToken, getstocktransfer, } from "../utils/functions"
import {AuthTokenType, FranchiseeProps, stocktransferProps,} from "../utils/types"
import { useGetFranchisee, useGetstocktransfer } from "../utils/hooks"
import { Button,Drawer,Input, Modal, Select, Table, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { onhandUrl, sendstoUrl, stocktransferorderUrl, stocktransferupdatesUrl, viewstoUrl} from "../utils/network"
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileSaver from "file-saver"; // Import FileSaver.js library
const { Option } = Select;



const formatstocktransferDataProps = (
  stocktransferdata: stocktransferProps[],
  onCloseOrder: (stocktransferid: stocktransferProps) => void,
  onChangeAdd: (value: Date | null, stocktransfer_id: number) => void,
  onViewDetails: (item: stocktransferProps) => void,
  onDeleteOrder: (item: stocktransferProps) => void
) => {
  return stocktransferdata.map((item) => {
    let actionLabel = "";
    let actionHandler = () => {};

    if (item.status === "Open") {
      actionLabel = "Intransit";
      actionHandler = () => onCloseOrder(item);
    } else if (item.status === "Intransit") {
      actionLabel = "Close";
      actionHandler = () => onCloseOrder(item);
    }

    const changeDeliveryDateInput = item.status === "Intransit" ? (
      <div>
        <Input
          style={{ width: "150px" }}
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeAdd(e.target.valueAsDate, item.id)
          }
        />
      </div>
    ) : null;

    return {
      ...item,
      key: item.id,
      change_delivery_date: changeDeliveryDateInput,
      action: (
        <div>
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={actionHandler}
          >
            {actionLabel}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onViewDetails(item)}
          >
            Details
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onDeleteOrder(item)}
          >
            Delete
          </span>
        </div>
      ),
    };
  });
};


function StocktransferOrderReview(){
  const[modalState,setModalState]=useState(false)
  const[fetching,setFetching]=useState(true)
    
  const [stocktransfer,setstocktransfer]=useState<stocktransferProps[]>([])
  const [stocktransferadd,setstocktransferadd]=useState<{[key:number]:Date|null}>([])

  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };




  useGetstocktransfer(setstocktransfer,setFetching,selectedFranchisee)

  useEffect(() => {
    getstocktransfer(setstocktransfer, setFetching,selectedFranchisee);
        },[selectedFranchisee])


  const [loading,setLoading]=useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<stocktransferProps | null>(null);

  const [speedDialOpen, setSpeedDialOpen] = useState(false);



      const downloadsto= async (transferorder:stocktransferProps|any)=>{

        const datatosend={
          transfer_order_id:transferorder.id,
        }
      
          const headers=getAuthToken() as AuthTokenType
          
      
        const response:AxiosResponse =await axios.post(viewstoUrl,datatosend,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.error
            })
      
        }
            ) as AxiosResponse
          const pdfBlob = new Blob([response.data], { type: "application/pdf" });
          FileSaver.saveAs(pdfBlob, `Transfer_Order_${datatosend.transfer_order_id}.pdf`);
                     setLoading(false)
      
          }
    



  const showModal = (item: stocktransferProps) => {
    setCurrentItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };




      const stocktransferordercolumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },

          {
            title: 'Expected Delivery Date',
            dataIndex: 'expected_delivery_date',
            key: 'expected_delivery_date',
          },
          {
            title: 'Actual Delivery Date',
            dataIndex: 'actual_delivery_date',
            key: 'actual_delivery_date',
          },
  
          {
            title: 'Origin Warehouse',
            dataIndex: 'origin',
            key: 'origin',
            filters: stocktransfer.map(item => ({
              text: item.from_location ? item.from_location.name : 'N/A',
              value: item.from_location ? item.from_location.name : 'N/A'
            })),
            onFilter: (value:any, record:any) => record.from_location.name.toLowerCase().includes(value.toLowerCase()),

          },
          {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination',
            filters: stocktransfer.map(item => ({
              text: item.to_location ? item.to_location.name : 'N/A',
              value: item.to_location ? item.to_location.name : 'N/A'
            })),
            onFilter: (value:any, record:any) => record.to_location.name.toLowerCase().includes(value.toLowerCase()),

          },
          {
            title: 'Internal Reference',
            dataIndex: 'internal_reference',
            key: 'internal_reference',
          },
          {
            title: 'Portfolio',
            dataIndex: 'portfolio',
            key: 'portfolio',
          },
          {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
          },
          {
            title: 'Transport Company',
            dataIndex: 'transport_company',
            key: 'transport_company',
          },
          {
            title: 'Truck Plate',
            dataIndex: 'truck_plate',
            key: 'truck_plate',
          },
          {
            title: 'Guide Number',
            dataIndex: 'guide_number',
            key: 'guide_number',
          },



          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: 'Change Delivery Date',
            dataIndex: 'change_delivery_date',
            key: 'change_delivery_date',
          },

          {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
          },
        ];


        const Closeorder = async (stocktransferorder: stocktransferProps | any) => {
          let statusToUpdate = "";
        
          if (stocktransferorder.status === "Open") {
            statusToUpdate = "Intransit";
            for (let i = 0; i < stocktransferorder.stocktransferorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.from_location.id,
                status: "Available",
                totalunits: -stocktransferorder.stocktransferorder_items[i].quantity
              };
              const datatosend2inv2 = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.to_location.id,
                status: "Intransit",
                totalunits: stocktransferorder.stocktransferorder_items[i].quantity
              };

              
        
              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
              await axios.post(onhandUrl, datatosend2inv2, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
            }
            
            
          } else if (stocktransferorder.status === "Intransit") {
            statusToUpdate = "Closed";
            for (let i = 0; i < stocktransferorder.stocktransferorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.to_location.id,
                status: "Intransit",
                totalunits: -stocktransferorder.stocktransferorder_items[i].quantity
              };

              const datatosend2inv2 = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.to_location.id,
                status: "Available",
                totalunits: stocktransferorder.stocktransferorder_items[i].quantity
              };

        
              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              })
              await axios.post(onhandUrl, datatosend2inv2, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
            }
          }
        
          const datatosend:any = {
            id: stocktransferorder.id,
            status: statusToUpdate,
          };
          
          if (statusToUpdate === 'Closed') {
            datatosend.actual_delivery_date = stocktransferadd[stocktransferorder.id];
          }

        
          setLoading(true);
          const headers = getAuthToken() as AuthTokenType;
        
          const response: AxiosResponse = await axios.patch(
            stocktransferupdatesUrl + "/" + datatosend.id,
            datatosend,
            headers
          ).catch((e) => {
            notification.error({
              message: "Operation Error",
              description: e.response?.data.error,
            });
          }) as AxiosResponse;
        
          setLoading(false);
        
          if (response) {
            notification.success({
              message: "Operation Success",
              description: "Order " + statusToUpdate + " Successfully",
            });
          }
        
          getstocktransfer(setstocktransfer, setFetching,selectedFranchisee);
        };

    const DeleteOrder= async (stocktransferorder:stocktransferProps|any)=>{

      const datatosend={
        id:stocktransferorder.id,
      }
    
        
      const headers=getAuthToken() as AuthTokenType
    
      const response:AxiosResponse =await axios.delete(stocktransferupdatesUrl+'/'+datatosend.id,headers).catch(
          (e)=>{ notification.error({
              message:"Operation Error",
              description:e.response?.data.error
          })
    
      }
          ) as AxiosResponse
    
          setLoading(false)
    
          if(response){
              notification.success({
                  message:"Operation Success",
                  description:"Order Deleted Successfully"
                  
              })
    
          }       
          getstocktransfer(setstocktransfer, setFetching,selectedFranchisee);       

        }
    

             
  const onChangeAdd=(value:Date|null,stocktransfer_id:number)=>{
          setstocktransferadd({
            ...stocktransferadd,
            [stocktransfer_id]:value
          })

        }
        const downloadCSV = () => {
          const csvContent = "data:text/csv;charset=utf-8," +
            stocktransferordercolumns.map(column => column.title).join(',') + '\n' +
            stocktransfer.map(row => stocktransferordercolumns.map(column => {
              return `"${row[column.dataIndex as keyof stocktransferProps]}"`;
            }).join(',')).join('\n');
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
        };
        const [searchQuery, setSearchQuery] = useState("");



        return (
          <>
          <div className="card">
              <div className="cardHeader">
                  <h2 className="headContent">Transfer Orders</h2>
                  <div className="rightContent">
                  <Input
              placeholder="Search..."
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              style={{ marginRight: '10px' }}
            />
                                  <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>


            <Button onClick={downloadCSV}>Download</Button>

                        </div>
              
              </div>
              <br/>
              <Table className="custom-table"
              
              dataSource={(formatstocktransferDataProps(stocktransfer,Closeorder,onChangeAdd,showModal,DeleteOrder)).filter(record =>
                Object.values(record).some(value =>
                  value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                ))}

              columns={stocktransferordercolumns}
              
               
              size="small"
              loading ={fetching}/>
<Drawer
  title="Details"
  visible={isModalVisible}
  placement="right"
  onClose={handleOk}
  style={{ borderRadius: '10px' }} // Add rounded corners to the drawer
>
  {currentItem && (
    <>
      <div style={{ marginBottom: '20px', borderBottom: '1px solid #e8e8e8', paddingBottom: '10px' }}>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>ID: {currentItem.id}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>From Location: {currentItem.from_location?.name}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>To Location: {currentItem.to_location?.name}</p>
      </div>
      <div>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>Items:</p>
        <Table
          className="custom-table"
          dataSource={currentItem.stocktransferorder_items}
          columns={[
            {
              title: 'Item Name',
              dataIndex: ['item', 'name'],
              key: 'name',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              key: 'quantity',
              render: (text: number) => <span>{JSON.stringify(text)}</span>,
            },
          ]}
          pagination={false}
          bordered
          size="small"
          style={{ marginTop: '20px' }}
        />
      </div>
    </>
  )}
  <SpeedDial
  ariaLabel="SpeedDial"
  icon={<SpeedDialIcon />}
  onClose={() => setSpeedDialOpen(false)}
  onOpen={() => setSpeedDialOpen(true)}
  open={speedDialOpen}
  direction="up"
  FabProps={{ size: 'small' }} 
>

<SpeedDialAction
key="Download"
icon={<PictureAsPdfIcon />}
tooltipTitle="Download "
onClick={() => downloadsto(currentItem)}
/>
</SpeedDial>
</Drawer>
          </div>
          </> 
        )
    }
    
    export default StocktransferOrderReview