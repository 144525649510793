import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag } from "antd";
import { useGetCategory } from "../utils/hooks";
import { AuthTokenType, category } from "../utils/types";
import { getAuthToken, getCategory } from "../utils/functions";
import AddCategoryForm from "../components/AddCategoryForm";
import axios from "axios";
import { categoriesUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";

const Categories: FC = () => {
  const [filterValues, setFilterValues] = useState<{ [key: string]: string[] }>({});
  
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Parent",
      dataIndex: 'par',
      key: 'par',
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: category) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [categories, setCategories] = useState<category[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState<category | null>(null);


  useGetCategory(setCategories, setFetching);


  
  useEffect(() => {
    // When editingCategory changes, open the modal
    if (editingCategory) {
      setDrawerVisible(true);
    }
  }, [editingCategory]);

  const onCloseWithoutEditing = () => {
    setEditingCategory(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: category) => {
    setEditingCategory(record);
  };
  const handleDelete = (categoryId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this category?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a category
          await axios.delete(`${categoriesUrl}/${categoryId}/`, headers);
  
          // After successful deletion, fetch updated categories
          getCategory(setCategories, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };
  const onCreateCategory = () => {
    setDrawerVisible(true);
    setEditingCategory(null); // Reset editingCategory for new category
  };

  return (
    <ContentLayout
      pageTitle="Categories"
      buttontitle="Add Category"
      setModalState={setDrawerVisible}
      dataSource={categories as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
      <AddCategoryForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getCategory(setCategories, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingCategory={editingCategory}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Categories;