import { Drawer, Form, Input, Select, Button, notification } from 'antd';
import { FC, useState } from 'react';
import { AddOnHandInventoryFormProps, AuthTokenType, DataProps, location, product } from '../utils/types';
import { getAuthToken } from '../utils/functions';
import axios, { AxiosResponse } from 'axios';
import { onhandUrl} from '../utils/network';
import { useGetLocation, useGetProduct } from '../utils/hooks';

const { Option } = Select;

const AddOnHandInventoryForm: FC<AddOnHandInventoryFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<product[]>([]);
  const [locations, setLocations] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  useGetProduct(setProducts, setFetching);

  useGetLocation(setLocations, setFetching);


  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    const response: AxiosResponse = await axios.post(onhandUrl, values, headers).catch((e) => {
      notification.error({
        message: 'Operation Error',
        description: e.response?.data.error,
      });
      form.resetFields();
    }) as AxiosResponse;
    setLoading(false);

    if (response) {
      notification.success({
        message: 'Operation Success',
        description: 'Inventory Added Successfully',
      });
      onSuccessCallBack();
      form.resetFields();
    }
    return null;
  };

  return (
    <Drawer
      title="Add On Hand Inventory"
      visible={isVisible}
      placement="right"
      onClose={onClose}
      footer={null}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label="Product" name="sku_code_id">
        <Select>
            <Select.Option value="" disabled>Select Product</Select.Option>
            {products.map(product => (
              <Select.Option key={product.id} value={product.id}>
                {product.name}
              </Select.Option>
            ))}
          </Select>

        </Form.Item>

        <Form.Item label="Location" name="storage_location_id">
        <Select>
            <Select.Option value="" disabled>Select Storage Location</Select.Option>
            {locations.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please Input Status of Inventory' }]}
        >
 <Select placeholder="Select Status">
          <Select.Option value="Available">Available</Select.Option>
          {/* Add more options here if needed */}
        </Select>        
        </Form.Item>

        <Form.Item
          label="Total Units"
          name="totalunits"
          rules={[{ required: true, message: 'Please Input Total Units' }]}
        >
          <Input placeholder="Total Units" type="totalunits" />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddOnHandInventoryForm;