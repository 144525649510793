import React, { useEffect, useState } from "react";
import { Drawer, Row, Col, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType } from "../utils/types";
import { purchaseorderfilesUrl, transferorderfilesUrl } from "../utils/network";

interface PurchaseOrderFilesDrawerProps {
  purchaseorderId: number | null;
  visible: boolean;
  onClose: () => void;
}

const PurchaseOrderFilesDrawer: React.FC<PurchaseOrderFilesDrawerProps> = ({ purchaseorderId, visible, onClose }) => {
  const [files, setFiles] = useState<any[]>([]);

  const fetchFiles = async () => {
    try {
      const data = { purchaseorder_id: purchaseorderId };
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(purchaseorderfilesUrl + '/get_files_by_purchaseorder', data, headers);
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    if (visible && purchaseorderId) {
      fetchFiles();
    }
  }, [visible, purchaseorderId]);

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName || "file.pdf"); // Default filename if undefined
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Drawer
      title={`Files for Transfer Order ID ${purchaseorderId}`}
      placement="right"
      visible={visible}
      onClose={onClose}
      width="50%"
      height="100%"
    >
      <div style={{ overflowY: "auto", height: "100%" }}>
        <Row gutter={[16, 16]}>
          {files.map((file, index) => (
            <Col key={index} span={24}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
                <a href={file?.pdf_file} target="_blank" rel="noopener noreferrer">
                  {file?.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1)}
                </a>
                <a
                  onClick={() => handleDownload(file?.pdf_file, file?.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1))}
                  style={{ marginRight: 8, color: "blue", cursor: "pointer" }}
                >
                  <DownloadOutlined />
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Drawer>
  );
};

export default PurchaseOrderFilesDrawer;