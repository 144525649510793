import React, { FC, useEffect, useState } from "react";
import { Button, Table, Modal, notification, Drawer } from "antd";
import { useGetFranchisee, useGetUsers } from "../utils/hooks";
import { AuthTokenType, FranchiseeProps, UserProps } from "../utils/types";
import { getAuthToken, getFranchisee, getUsers } from "../utils/functions";
import AddUserForm from "../components/AddUserForm";
import axios from "axios";
import {FranchiseeUrl, UsersUrl } from "../utils/network";
import moment from "moment";
import BadgeIcon from '@mui/icons-material/Badge';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddFranchiseeForm from "../components/AddFranchiseeForm";

const Franchisee: FC = () => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Franchisee',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Admin Email',
      dataIndex: 'host_user',
      key: 'host_user',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: FranchiseeProps) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingFranchisee, seteditingFranchisee] = useState<FranchiseeProps | null>(null);
  const [fetching, setFetching] = useState(true);
  const [Franchisee, setFranchisee] = useState<FranchiseeProps[] | undefined>();

  useGetFranchisee(setFranchisee, setFetching);

  const onCloseWithoutEditing = () => {
    seteditingFranchisee(null); // Reset editingCategory when form is closed without editing
  };

  useEffect(() => {
    // When editingUser changes, open the drawer
    if (editingFranchisee) {
      setDrawerVisible(true);
    }
  }, [editingFranchisee]);

  const handleEdit = (record: FranchiseeProps) => {
    seteditingFranchisee(record);
  };

  const handleDelete = (FranchiseeId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this Franchisee?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a user
          await axios.delete(`${FranchiseeUrl}/${FranchiseeId}`, headers);

          // After successful deletion, fetch updated users
          getFranchisee(setFranchisee, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCreateUser = () => {
    setDrawerVisible(true);
    seteditingFranchisee(null); // Reset editingUser for new user
  };

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Franchisees</h2>
        <div className="rightContent">
          <Button onClick={onCreateUser}>Add Franchisee</Button>
        </div>
      </div>
      <br />
      <Table
        className="custom-table"
        dataSource={(Franchisee as unknown) as FranchiseeProps[]}
        columns={columns}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={fetching}
      />
      <AddFranchiseeForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getFranchisee(setFranchisee, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingFranchisee ={editingFranchisee}
        onCloseWithoutEditing={onCloseWithoutEditing}

      />
    </div>
  );
};

export default Franchisee;