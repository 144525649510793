import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select } from "antd";
import { AuthTokenType, DataProps, AddCustomerFormProps, customer, location } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { customersUrl, customerssalesUrl, customersupdatesUrl } from "../utils/network";
import { useGetLocation } from "../utils/hooks";

const AddCustomerForm: FC<AddCustomerFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingCustomer,
  onCloseWithoutEditing, // new prop

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [locations, setlocations] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  useGetLocation(setlocations, setFetching);

  const [hasChanges, setHasChanges] = useState(false);
  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  useEffect(() => {
    if (editingCustomer) {
      form.setFieldsValue({...editingCustomer,
        cusomter_location_id:editingCustomer.customer_location?.id
      });
    } else {
      form.resetFields();
    }
  }, [editingCustomer, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingCustomer) {
        // Editing customer
        response = await axios.put(`${customersupdatesUrl}/${editingCustomer.id}/`, values, headers);
      } else {
        // Adding new customer
        response = await axios.post(customersUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingCustomer ? "Edit Customer" : "Add Customer"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the customer name!' }]}
        >
          <Input placeholder="Customer Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input the customer email!' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please input the customer address!' }]}
        >
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input the customer city!' }]}
        >
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please input the customer state!' }]}
        >
          <Input placeholder="State" />
        </Form.Item>
        <Form.Item
          label="Zip"
          name="zip"
          rules={[{ required: true, message: 'Please input the customer Zip!' }]}
        >
          <Input placeholder="Zip" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'Please input the customer phone number!' }]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: 'Please input the customer type!' }]}
        >
          <Input placeholder="Type" />
        </Form.Item>
        <Form.Item
          label="Location"
          name="customer_location_id"
          rules={[{ required: false, message: 'Please select Location!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a Location</Select.Option>
            {locations.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingCustomer ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCustomerForm;