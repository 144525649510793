import React, { useEffect, useState } from 'react';
import { Tabs, Table, Spin, Input, Select } from 'antd';
import { FranchiseeProps, HeaderProfitLossprops, ProfitLossStatementprops } from '../utils/types';
import { useGetFranchisee, useGetSankey, useGetheaderprofitloss, useGetprofitloss } from '../utils/hooks';
import { getheaderprofitloss, getprofitloss } from '../utils/functions';
import Sankeychart from '../components/Dashboard/Sankeychart';

const { TabPane } = Tabs;
const { Option } = Select;

const ProfitLossComponent: React.FC = () => {


  const userRole = localStorage.getItem('token1');


  const [headerProfitLoss, setHeaderProfitLoss] = useState<HeaderProfitLossprops[]>([]);
  const [profitLoss, setProfitLoss] = useState<ProfitLossStatementprops[]>([]);
  const [fetchingHeaderProfitLoss, setFetchingHeaderProfitLoss] = useState<boolean>(true);
  const [fetchingProfitLoss, setFetchingProfitLoss] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState<number | string>('All');
  const [selectedYear, setSelectedYear] = useState<number | string>('All');
  const [fetching, setFetching] = useState<boolean>(true);

  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };


  useGetheaderprofitloss(setHeaderProfitLoss, setFetchingHeaderProfitLoss,selectedYear,selectedMonth,selectedFranchisee);
  useGetprofitloss(setProfitLoss, setFetchingProfitLoss,selectedYear,selectedMonth,selectedFranchisee);

  useEffect(() => {
    getheaderprofitloss(setHeaderProfitLoss, setFetchingHeaderProfitLoss,selectedYear,selectedMonth,selectedFranchisee);
    getprofitloss(setProfitLoss, setFetchingProfitLoss,selectedYear,selectedMonth,selectedFranchisee);
  },[selectedYear,selectedMonth,selectedFranchisee])

  const handleMonthChange = (value: number | string) => {
    setSelectedMonth(value);
  };


  const handleYearChange = (value: number | string) => {
    setSelectedYear(value);
  };

  const headerProfitLossColumns = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Header',
      dataIndex: 'header',
      key: 'header',
      render: (header: HeaderProfitLossprops['header']) => header.name,
    },
    {
      title: 'Subheader',
      dataIndex: 'subheader',
      key: 'subheader',
      render: (subheader: HeaderProfitLossprops['subheader']) => subheader.name,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  const profitLossColumns = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Total Income',
      dataIndex: 'total_income',
      key: 'total_income',
    },
    {
      title: 'Total Expense',
      dataIndex: 'total_expense',
      key: 'total_expense',
    },
    {
      title: 'Overall Profit',
      dataIndex: 'overall_profit',
      key: 'overall_profit',
    },
  ];


  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Statements</h2>
        <div className="rightContent">
          <Input placeholder="Search..." style={{ marginRight: 10 }} />
          {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200, marginRight: 10 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
          )}
          <Select
            placeholder="Select Month"
            onChange={handleMonthChange}
            style={{ width: 120, marginRight: 10 }}
            allowClear
          >
            <Option value="All">All</Option>
            {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
              <Option key={month} value={month}>{month}</Option>
            ))}
          </Select>
          <Select
            placeholder="Select Year"
            onChange={handleYearChange}
            style={{ width: 120 }}
            allowClear
          >
            <Option value="All">All</Option>
            {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
              <Option key={year} value={year}>{year}</Option>
            ))}
          </Select>
        </div>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Details by Headers" key="1">
          {fetchingHeaderProfitLoss ? (
            <Spin />
          ) : (
            <Table
              className="custom-table"
              dataSource={headerProfitLoss}
              columns={headerProfitLossColumns}
              rowKey={(record) => `${record.header.id}-${record.subheader.id}`}
              pagination={false}
            />
          )}
        </TabPane>
        <TabPane tab="Overall Profit Loss" key="2">
          {fetchingProfitLoss ? (
            <Spin />
          ) : (
            <Table
              className="custom-table"
              dataSource={profitLoss}
              columns={profitLossColumns}
              rowKey={(record) => `${record.year}-${record.month}`}
              pagination={false}
            />
          )}
        </TabPane>
        <TabPane tab="Analysis" key="3">
        <Sankeychart
            month={selectedMonth}
            year={selectedYear}
            franchisee={selectedFranchisee}
          />
          </TabPane>
      </Tabs>
    </div>
  );
};

export default ProfitLossComponent;