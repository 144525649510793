import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Select, Input } from "antd";
import { useGetFranchisee, useGetLead } from "../utils/hooks";
import { AuthTokenType, FranchiseeProps, lead } from "../utils/types";
import { getAuthToken, getLead } from "../utils/functions";
import AddLeadForm from "../components/AddLeadForm";
import axios from "axios";
import { leadsupdateurls } from "../utils/network";

const { Option } = Select;

const Leads: FC = () => {

  const userRole = localStorage.getItem('token1');

  const [leads, setLeads] = useState<lead[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingLead, setEditingLead] = useState<lead | null>(null);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee

  useGetLead(setLeads, setFetching, selectedFranchisee);


  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = leads.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );


  const getColumnFilterValues = (data: lead[], key: keyof lead) => {
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
};

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filters: getColumnFilterValues(leads, "id").map((id) => ({ text: id.toString(), value: id })),
      onFilter: (value: number | string, record: lead) => record.id === value,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filters: getColumnFilterValues(leads, "name").map((name) => ({ text: name, value: name })),
      onFilter: (value: string, record: lead) => record.name === value,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filters: getColumnFilterValues(leads, "email").map((email) => ({ text: email, value: email })),
      onFilter: (value: string, record: lead) => record.email === value,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      filters: getColumnFilterValues(leads, "address").map((address) => ({ text: address, value: address })),
      onFilter: (value: string, record: lead) => record.address === value,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      filters: getColumnFilterValues(leads, "city").map((city) => ({ text: city, value: city })),
      onFilter: (value: string, record: lead) => record.city === value,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      filters: getColumnFilterValues(leads, "state").map((state) => ({ text: state, value: state })),
      onFilter: (value: string, record: lead) => record.state === value,
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
      filters: getColumnFilterValues(leads, "zip").map((zip) => ({ text: zip, value: zip })),
      onFilter: (value: string, record: lead) => record.zip === value,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      filters: getColumnFilterValues(leads, "phone").map((phone) => ({ text: phone, value: phone })),
      onFilter: (value: string, record: lead) => record.phone === value,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      filters: getColumnFilterValues(leads, "source").map((source) => ({ text: source, value: source })),
      onFilter: (value: string, record: lead) => record.source === value,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: lead) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getLead(setLeads, setFetching, selectedFranchisee);
  }, [selectedFranchisee]);

  useEffect(() => {
    // When editingLead changes, open the modal
    if (editingLead) {
      setDrawerVisible(true);
    }
  }, [editingLead]);

  const onCloseWithoutEditing = () => {
    setEditingLead(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: lead) => {
    setEditingLead(record);
  };

  const handleDelete = (leadId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this lead?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a lead
          await axios.delete(`${leadsupdateurls}/${leadId}`, headers );

          // After successful deletion, fetch updated leads
          getLead(setLeads, setFetching, selectedFranchisee);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  const handleExportToCSV = () => {
    // Convert data to CSV format
    const csvContent =
      columns
        .map((column) => column.title)
        .join(",") +
      "\n" +
      leads
        .map((row) =>
          columns
            .map((column) => {
              const key = column.key as keyof lead;
              return row[key] !== undefined ? row[key] : "";
            })
            .join(",")
        )
        .join("\n");

    // Create a Blob object containing the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "leads.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Leads</h2>
        <div className="rightContent">
        <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
{userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
)}
          <Button onClick={() => setDrawerVisible(true)}>Add Lead</Button>
          <Button onClick={handleExportToCSV}>Download</Button>
        </div>
      </div>
      <br />
      <Table
        className="custom-table"
        dataSource={leads}
        columns={columns as any}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={fetching}
      />
      <AddLeadForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getLead(setLeads, setFetching, selectedFranchisee);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingLead={editingLead}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </div>
  );
};

export default Leads;