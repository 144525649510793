import { useContext } from "react";
import { store } from "../utils/store";
import SummaryData from "../components/Dashboard/summaryData";
import InventoryTable from "./InventoryTable";
import SummaryDashboard from "../components/SummaryDashboard";



function Home(){
  const{state}=useContext(store)
  return (
      <div>
          <div>
              <SummaryDashboard/>
          </div>
              <div >
                <InventoryTable/>
              </div>
              <br/>
          </div>
  )
}
export default Home