import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification } from "antd";
import { useGetSupplier } from "../utils/hooks";
import { AuthTokenType, supplier } from "../utils/types";
import { getAuthToken, getSupplier } from "../utils/functions";
import AddSupplierForm from "../components/AddSupplierForm";
import axios from "axios";
import { suppliersUrl, supplierupdatesUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";

const Suppliers: FC = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",

    },
    {
      title: 'Franchisee',
      dataIndex: ['franchisee','name'],
      key: ['franchisee','name'],
    },
    {
      title: "Location",
      dataIndex: ["supplier_location", "name"],
      key: ["supplier_location", "name"],
    },
    // Add other columns based on the supplier model
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: supplier) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [suppliers, setSuppliers] = useState<supplier[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [formVisible, setFormVisible] = useState(false);
  const [editingSupplier, setEditingSupplier] = useState<supplier | null>(null);

  useGetSupplier(setSuppliers, setFetching);

  useEffect(() => {
    // When editingSupplier changes, open the form
    if (editingSupplier) {
      setFormVisible(true);
    }
  }, [editingSupplier]);


  const onCloseWithoutEditing = () => {
    setEditingSupplier(null); // Reset editingCategory when form is closed without editing    
  };

  const handleEdit = (record: supplier) => {
    setEditingSupplier(record);
  };

  const handleDelete = (supplierId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this supplier?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a supplier
          await axios.delete(`${supplierupdatesUrl}/${supplierId}/`, headers);

          // After successful deletion, fetch updated suppliers
          getSupplier(setSuppliers, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCreateSupplier = () => {
    setFormVisible(true);
    setEditingSupplier(null); // Reset editingSupplier for new supplier
  };

  return (
    <ContentLayout // Wrap your content with ContentLayout component
      pageTitle="Suppliers"
      buttontitle="Add Supplier"
      setModalState={setFormVisible}
      dataSource={suppliers as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
      <AddSupplierForm
        onSuccessCallBack={() => {
          setFormVisible(false);
          getSupplier(setSuppliers, setFetching);
        }}
        isVisible={formVisible}
        onClose={() => setFormVisible(false)}
        editingSupplier={editingSupplier}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Suppliers;