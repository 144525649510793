import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Drawer, notification, Input, Button, Modal } from "antd";
import axios from "axios";
import { getAuthToken } from "../utils/functions";
import { FranchiseekeyUrl, FranchiseeUrl, paymentsUrl, UsersUrl } from "../utils/network";
import { AuthTokenType } from "../utils/types";
import CheckoutForm from "./Checkoutform";


const StripePaymentButton: React.FC<{ salesId: number }> = ({ salesId }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");
  const [amount, setAmount] = useState<number | "">("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [stripePromise, setStripePromise] = useState<Promise<any | null> | null>(null);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
  };

  useEffect(() => {
    const fetchPublicKey = async () => {
      try {
        const headers = getAuthToken() as AuthTokenType;
        const response = await axios.get(`${FranchiseekeyUrl}/get_stripe_public_key`, headers);
        const publicKey = response.data.stripe_public_key;
        const stripe = loadStripe(publicKey);
        setStripePromise(stripe);
        // Initialize Stripe with the public key
       } catch (error) {
        notification.error({
          message: "Error",
          description: "Unable to fetch the Stripe public key.",
        });
      }
    };

    fetchPublicKey();
  }, []);

  const handlePayClick = async () => {
    if (!amount || amount <= 0) {
      notification.error({
        message: "Invalid Amount",
        description: "Please enter a valid amount.",
      });
      return;
    }

    setLoading(true);

    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(`${paymentsUrl}/create_payment_intent/`, { sales_id: salesId, amount:amount }, headers);
      setClientSecret(response.data.clientSecret);
      setDpmCheckerLink(response.data.dpmCheckerLink);
      setModalVisible(false); // Close the modal
      setVisible(true); // Open the drawer
    } catch (error) {
      notification.error({
        message: "Payment Error",
        description: "There was an error creating the payment intent.",
      });
    } finally {
      setLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };


  return (
    <div style={{ display: 'inline-block' }}>
             <span
              style={{ color: "blue",cursor: "pointer" }}
              onClick={() => setModalVisible(true)}
            >
              Pay
            </span>
 
      <Modal
        title="Enter Amount"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handlePayClick}
        okText="Proceed"
        cancelText="Cancel"
      >
        <Input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter amount"
        />
      </Modal>

      <Drawer
        title="Payment"
        placement="right"
        onClose={() => setVisible(false)}
        visible={visible}
        width={400}
      >
        {clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm clientSecret={clientSecret} salesId={salesId} dpmCheckerLink={dpmCheckerLink} />
          </Elements>
        ) : (
          <div>Loading...</div>
        )}
      </Drawer>
    </div>
  );
};

export default StripePaymentButton;