import React, { useEffect, useState, ChangeEvent } from "react";
import { getAuthToken } from "../utils/functions";

import {
  AddRemovePurchaseItemProps,
  AuthTokenType,
  currency,
  location,
  product,
  purchaseProps,
  supplier,
} from "../utils/types";
import { useGetProduct, useGetLocation, useGetSupplier } from "../utils/hooks";
import { Button, Form, Input, Table, notification, Select } from "antd";
import axios, { AxiosResponse } from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { purchaseorderUrl } from "../utils/network";
import { ForkRight } from "@mui/icons-material";

const { Option } = Select;

const formatProductProps = (
  product: product[],
  onAddItem: (productdata: product) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return product.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "50px" }}
          type="number"
          min={1}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id as any)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onAddItem(item)}
        >
          +
        </Button>
      </div>
    ),
  }));
};

const formatPurchaseDataProps = (
  purchasedata: purchaseProps[],
  onRemoveItem: (productid: number) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return purchasedata.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "50px" }}
          type="number"
          min={1}
          max={item.quantity}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onRemoveItem(item.id)}
        >
          -
        </Button>
      </div>
    ),
  }));
};

function PurchaseOrder() {
  const [fetching, setFetching] = useState(true);
  const [product, setProduct] = useState<product[]>([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [purchasedata, setPurchaseData] = useState<purchaseProps[]>([]);
  const [purchaseitemqty, setPurchaseItemQty] =
    useState<AddRemovePurchaseItemProps>({});
  const [purchaseitemqdataty, setPurchaseItemDataQty] =
    useState<{ [key: number]: number }>({});

  const [fromlocation, setFromLocation] = useState<supplier[]>([]);
  const [fromlocationselected, setFromLocationSelected] = useState("");
  const [loading, setLoading] = useState(false);

  const [edd, Setedd] = useState<Date | null>(new Date());
  const [depdate, Setdepdate] = useState<Date | null>(new Date());

  const [tolocationselected,setToLocationSelected]=useState("")
  const [tolocation,setToLocation]=useState<location[]>([])



  const productcolumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={("Search code")}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<ForkRight />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {("Search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm({ closeDropdown: false }); // This will reset the filters and trigger a table update
            }}
            size="small"
            style={{ width: 90 }}
          >
            {("Reset")}
          </Button>
        </div>
      ),
      filterIcon: (filtered:boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value:any, record:any) => record.code.toString().toLowerCase().includes(value.toLowerCase()),


    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={("Search code")}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<ForkRight />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {("Search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm({ closeDropdown: false }); // This will reset the filters and trigger a table update
            }}
            size="small"
            style={{ width: 90 }}
          >
            {("Reset")}
          </Button>
        </div>
      ),
      filterIcon: (filtered:boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value:any, record:any) => record.code.toString().toLowerCase().includes(value.toLowerCase()),


    },
    {
      title: "Cost",
      dataIndex: "sku_cost_new",
      key: "sku_cost_new",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (currency: currency) => currency.name,

    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
    },
  ];

  const purchaseordercolumns = [
    {
      title: "Name",
      dataIndex: "item",
      key: "item",
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
    },
  ];

  useGetProduct(setProduct, setFetching);

  useGetSupplier(setFromLocation, setFetching);
  useGetLocation(setToLocation, setFetching);

  const additemtopurchasedata = (productdata: product) => {
    const qty = purchaseitemqty[productdata.id as any] || 1;

    let _purchasedata: purchaseProps[] = [];

    const id = purchasedata.filter(
      (id) => id.id === (productdata.id as any)
    );
    if (id.length > 0) {
      _purchasedata = purchasedata.map((id) => {
        if (id.id === (productdata.id as any)) {
          const _qty = id.quantity + qty;
          return {
            ...id,
            quantity: _qty,
            amount:productdata.sku_buy_cost*(_qty),


          };
        }
        return id;
      });
    } else {
      const temppurchasedata: purchaseProps = {
        key: productdata.id as any,
        item: productdata.name as any,
        id: productdata.id as any,
        quantity: qty,
        cost:productdata.sku_buy_cost,

        amount:productdata.sku_buy_cost*qty,
     
     

      };

      _purchasedata = [...purchasedata, temppurchasedata];
    }
    setPurchaseData(_purchasedata);
  };

  const removeitemfrompurchasedata = (productid: number) => {
    const qty = purchaseitemqdataty[productid] || 1;
    let _purchasedata: purchaseProps[] = [];
    const item = purchasedata.filter((item) => item.id === productid)[0];

    if (qty >= item.quantity) {
      _purchasedata = purchasedata.filter((item) => item.id !== productid);
    } else {
      _purchasedata = purchasedata.map((item) => {
        if (item.id === productid) {
          return {
            ...item,
            quantity: item.quantity - qty,
            amount:(item.cost)*(item.quantity-qty)         


          };
        }
        return item;
      });
    }
    setPurchaseData(_purchasedata);
  };

  const changepurchaseqty = (value: number, product_id: number) => {
    setPurchaseItemQty({
      ...purchaseitemqty,
      [product_id]: value,
    });
  };

  const changepurchaseremoveqty = (value: number, product_id: number) => {
    setPurchaseItemDataQty({
      ...purchaseitemqty,
      [product_id]: value,
    });
  };

  const clearPurchaseOrderData = () => {
    setPurchaseData([]);
    setPurchaseItemDataQty([]);
    form.resetFields();
  };


  const submitsto = async () => {

    if (purchasedata.length < 1) {
      notification.error({
        message: "Add Items to Order",
      });
    } else {
      const totalAmount = purchasedata.reduce((acc, item) => {
        if (item.amount !== undefined) {
          return acc + item.amount;
        }
        return acc;
      }, 0); 
      const datatosend = {
        from_location_id: fromlocationselected,
        to_location_id: tolocationselected,
        expected_delivery_date: edd,
        actual_delivery_date: edd,
        status: "Open",
        amount:totalAmount,
        po_number: form.getFieldValue("po_number"),
        departure_date: form.getFieldValue("departure_date"),
      free_days: form.getFieldValue("free_days"),
      internal_reference: form.getFieldValue("internal_reference"),
      origin_country: form.getFieldValue("origin_country"),
      origin_port: form.getFieldValue("origin_port"),
      destination_port: form.getFieldValue("destination_port"),
      container_id: form.getFieldValue("container_id"),
      portfolio: form.getFieldValue("portfolio"),
      project: form.getFieldValue("project"),
      shipping_company: form.getFieldValue("shipping_company"),
      material_reference: form.getFieldValue("material_reference"),
      bill_of_lading: form.getFieldValue("bill_of_lading"),
      measure: form.getFieldValue("measure"),
      din_number: form.getFieldValue("din_number"),

        purchaseorder_items_data: purchasedata.map((item) => ({
          item_id: item.key,
          quantity: item.quantity,
          amount:item.amount
        })),
      };

      setLoading(true);

      const headers = getAuthToken() as AuthTokenType;

      try {
        const response: AxiosResponse = await axios.post(
          purchaseorderUrl,
          datatosend,
          headers
        );

        notification.success({
          message: "Operation Success",
          description: "Order Created Successfully",
        });
      } catch (e) {
        notification.error({
          message: "Operation Error",
        });
        form.resetFields();
      } finally {
        form.resetFields();
        setLoading(false);
      }
    }
  };
  const [form] = Form.useForm();

  return (
    <div className="ordercreation">
      <div className="cardcart1">
        <div className="cardHeader">
          <h2 className="headContent">Products</h2>
        
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatProductProps(
            product,
            additemtopurchasedata,
            changepurchaseqty
          )}
          columns={productcolumns}
        />
      </div>
      <br />

      <div className="cardcart2">
        <div className="cardHeader">
          <h2 className="headContent">Purchase Order</h2>
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatPurchaseDataProps(
            purchasedata,
            removeitemfrompurchasedata,
            changepurchaseremoveqty
          )}
          columns={purchaseordercolumns}
        />
        <Form layout="vertical" form={form}>
          <br/>
          <Form.Item  name="from_location_id">
            <Select
              defaultValue=""
              onChange={(value) => setFromLocationSelected(value)}
            >
              <Option value="">Supplier</Option>
              {fromlocation.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item  name="to_location_id">
            <Select
              defaultValue=""
              onChange={(value) => setToLocationSelected(value)}
            >
              <Option value="">To Location</Option>
              {tolocation.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Expected Delivery Date" name="expected_delivery_date">
            <DatePicker selected={edd} onChange={(date) => Setedd(date)} />
          </Form.Item>
          {showAdditionalFields && (
            <>          
            <Form.Item
          label=""
          name="po_number"
          rules={[{ required: false, message: 'Please input the PO Number!' }]}
        >
          <Input placeholder="PO Number" />
        </Form.Item>
        <Form.Item label="Departure Date" name="departure_date">
          <DatePicker selected={depdate} onChange={(date) => Setdepdate(date)} />
        </Form.Item>
        <Form.Item
          label=""
          name="free_days"
          rules={[{ required: false, message: 'Please input the Free Days!' }]}
        >
          <Input placeholder="Free Days" />
        </Form.Item>
        <Form.Item
          label=""
          name="material_reference"
          rules={[{ required: false, message: 'Please input the Material Reference!' }]}
        >
          <Input placeholder="Material Reference" />
        </Form.Item>
        <Form.Item
          label=""
          name="measure"
          rules={[{ required: false, message: 'Please input the Measure!' }]}
        >
          <Input placeholder="Measure" />
        </Form.Item>
        <Form.Item
          label=""
          name="bill_of_lading"
          rules={[{ required: false, message: 'Please input the Bill of Lading!' }]}
        >
          <Input placeholder="Bill of Lading" />
        </Form.Item>
        <Form.Item
          label=""
          name="internal_reference"
          rules={[{ required: false, message: 'Please input the Internal Reference!' }]}
        >
          <Input placeholder="Internal Reference" />
        </Form.Item>
        <Form.Item
          label=""
          name="origin_country"
          rules={[{ required: false, message: 'Please input the Origin Country!' }]}
        >
          <Input placeholder="Origin Country" />
        </Form.Item>
        <Form.Item
          label=""
          name="origin_port"
          rules={[{ required: false, message: 'Please input the Origin Port!' }]}
        >
          <Input placeholder="Origin Port" />
        </Form.Item>
        <Form.Item
          label=""
          name="destination_port"
          rules={[{ required: false, message: 'Please input the Destination Port!' }]}
        >
          <Input placeholder="Destination Port" />
        </Form.Item>
        <Form.Item
          label=""
          name="container_id"
          rules={[{ required: false, message: 'Please input the Container ID!' }]}
        >
          <Input placeholder="Container ID" />
        </Form.Item>
        <Form.Item
          label=""
          name="portfolio"
          rules={[{ required: false, message: 'Please input the Portfolio!' }]}
        >
          <Input placeholder="Portfolio" />
        </Form.Item>
        <Form.Item
          label=""
          name="project"
          rules={[{ required: false, message: 'Please input the Project!' }]}
        >
          <Input placeholder="Project" />
        </Form.Item>
        <Form.Item
          label=""
          name="shipping_company"
          rules={[{ required: false, message: 'Please input the Shipping Company!' }]}
        >
          <Input placeholder="Shipping Company" />
        </Form.Item>
        <Form.Item
          label=""
          name="din_number"
          rules={[{ required: false, message: 'Please input the DIN Number!' }]}
        >
          <Input placeholder="DIN Number" />
        </Form.Item>
        </>
          )}
          <Button type="text" onClick={() => setShowAdditionalFields(!showAdditionalFields)}>
            {showAdditionalFields ? "Hide Additional Fields" : "Show Additional Fields"}
          </Button>
        </Form>
        <br/>
        <Button type="primary" onClick={submitsto}>
          Create Order
        </Button>
        <Button danger onClick={clearPurchaseOrderData}>
          Clear
        </Button>
      </div>
    </div>
  );
}

export default PurchaseOrder;




