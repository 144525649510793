import React, { useState } from "react";
import { notification, Input, Button, Modal } from "antd";
import axios, { AxiosResponse } from "axios";

import { getAuthToken } from "../utils/functions";
import { paymentsUrl, redirecturl, salesorderupdatesUrl } from "../utils/network";
import { AuthTokenType } from "../utils/types";

const ChargeCustomer: React.FC<{ salesId: number }> = ({ salesId }) => {
  const [amount, setAmount] = useState<number | "">("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
  };

  const notify = async (amount: number) => {
    const datatosend = {
      id: salesId,
      paid: amount,
    };

    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    const response: AxiosResponse = await axios.patch(salesorderupdatesUrl + '/' + datatosend.id, datatosend, headers).catch(
      (e) => {
        notification.error({
          message: "Operation Error",
          description: e.response?.data.error,
        });
      }
    ) as AxiosResponse;

    setLoading(false);

    if (response) {
      notification.success({
        message: "Operation Success",
        description: "Payment Success",
      });
      setTimeout(() => {
        window.location.href = redirecturl;
      }, 2000);
    }
  };


  const handlePayClick = async () => {
    if (!amount || amount <= 0) {
      notification.error({
        message: "Invalid Amount",
        description: "Please enter a valid amount.",
      });
      return;
    }

    setLoading(true);

    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(`${paymentsUrl}/charge_customer/`, 
        { sales_id: salesId, amount: amount }, 
        headers
      );

      notify(amount); // Notify backend of payment success

      setModalVisible(false); // Close the modal
    } catch (error) {
      notification.error({
        message: "Payment Error",
        description: "There was an error processing the payment.",
      });
    } finally {
      setLoading(false);
    }
  };


  
  return (
    <div style={{ display: 'inline-block' }}>
      <span
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => setModalVisible(true)}
      >
        Charge Customer
      </span>

      <Modal
        title="Enter Amount"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handlePayClick}
        okText="Proceed"
        cancelText="Cancel"
      >
        <Input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter amount"
        />
      </Modal>
    </div>
  );
};

export default ChargeCustomer;