import React, { FC, useState, useEffect } from 'react';
import { Button, Table, Modal, notification, Select } from 'antd';
import { useGetFranchisee, useGetOnhandIventory } from '../utils/hooks';
import { FranchiseeProps, OnhandInventoryProps } from '../utils/types';
import { getOnhandInventory } from '../utils/functions';
import AddOnHandInventoryForm from '../components/AddOnHandInventoryForm';
const { Option } = Select;

const OnHandInventory: FC = () => {


  const userRole = localStorage.getItem('token1');

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'SKU Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'SKU Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Category',
      dataIndex: 'cat',
      key: 'cat',
    },
    {
      title: 'Sub Category',
      dataIndex: 'subcat',
      key: 'subcat',
    },
    {
      title: 'Storage Location',
      dataIndex: 'storage',
      key: 'storage',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Total Units',
      dataIndex: 'totalunits',
      key: 'totalunits',
    },
    {
      title: 'Stock Date',
      dataIndex: 'created_at',
      key: 'created_at',
    },

  ];

  const [onhandinventory, setOnHandInventory] = useState<OnhandInventoryProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  useGetOnhandIventory(setOnHandInventory, setFetching,selectedFranchisee);


                 
  useEffect(() => {
    getOnhandInventory(setOnHandInventory, setFetching,selectedFranchisee);
        },[selectedFranchisee])


  const onCreateOnHandInventory = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onSuccessAddOnHandInventory = () => {
    setDrawerVisible(false);
    getOnhandInventory(setOnHandInventory, setFetching,selectedFranchisee); // Refresh on-hand inventory list
  };




  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">On Hand Inventory</h2>
        <div className="rightContent">
        {userRole === 'Admin' && (
        <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
        )}


          <Button onClick={onCreateOnHandInventory}>Add On Hand Inventory</Button>
        </div>
      </div>
      <br />
      <Table
        className="custom-table"
        dataSource={onhandinventory}
        columns={columns}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={fetching}
      />
      <AddOnHandInventoryForm
        isVisible={drawerVisible}
        onClose={onCloseDrawer}
        onSuccessCallBack={onSuccessAddOnHandInventory}
      />
    </div>
  );
};

export default OnHandInventory;