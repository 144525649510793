import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, InputNumber, Select, Button } from "antd";
import { AuthTokenType, DataProps, AddProductFormProps, unitofmeasure, category, currency, AddPricebylocationFormProps, product, location, FranchiseeProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import {useRef} from 'react';
import { useGetFranchisee, useGetLocation, useGetProduct } from "../utils/hooks";
import { PricebyLocationUrl } from "../utils/network";
const ImageHolder: string = require("../assets/ImageHolder.svg").default;

const AddPriceByLocation: FC<AddPricebylocationFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingPricebyLocation,
  onCloseWithoutEditing, // new prop

}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<product[]>([]);
  const [locations, setlocations] = useState<FranchiseeProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  useGetProduct(setProducts, setFetching);
  useGetFranchisee(setlocations, setFetching);

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (editingPricebyLocation) {
      form.setFieldsValue({...editingPricebyLocation,
        product_details_id:editingPricebyLocation.product_details.id,
        franchisee_id:editingPricebyLocation.franchisee?.id});
    } else {
      form.resetFields();
    }
  }, [editingPricebyLocation, form]);


  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;
  try{
      let response: AxiosResponse;
  
      if (editingPricebyLocation) {
        // Editing product
        response = await axios.put(`${PricebyLocationUrl}/${editingPricebyLocation.id}/`, values,headers);
      } else {
        // Adding new product
        response = await axios.post(PricebyLocationUrl + '/', values,headers);
      }
  
      setLoading(false);
      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: 'Operation Error',
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingPricebyLocation ? "Edit Price" : "Add Price"}
      visible={isVisible}
      onClose={handleFormClose}

      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Product"
          name="product_details_id"
          rules={[{ required: true, message: 'Please select Product!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a Product</Select.Option>
            {products.map(product => (
              <Select.Option key={product.id} value={product.id}>
                {product.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Location"
          name="franchisee_id"
          rules={[{ required: true, message: 'Please select Franchisee!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a Franchisee</Select.Option>
            {locations.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label="SKU Price"
          name="sku_price"
          rules={[{ required: true, message: 'Please input the SKU price!' }]}
        >
          <InputNumber />
        </Form.Item>
  
  
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingPricebyLocation ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddPriceByLocation;