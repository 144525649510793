import React, { useEffect, useState } from "react";
import { Table, message, Popconfirm, Modal, Select, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AuthTokenType, FranchiseeProps, estimatesProps} from "../utils/types";
import { useGetEstimates, useGetFranchisee} from "../utils/hooks";
import { getAuthToken, getestimates } from "../utils/functions";
import axios from "axios";
import { estimatephotosUrl} from "../utils/network";
import { Camera } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import EstimatePhotosDrawer from "../components/Estimatephotosdrawer";
const { Option } = Select;

const EstimatePhotos = () => {
  const userRole = localStorage.getItem('token1');

  const [fetching, setFetching] = useState(true);
  const [estimates, setestimates] = useState<estimatesProps[]>([]);
  const [fileLists, setFileLists] = useState<{ [key: number]: File[] }>({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedestimateid, setselectedestimateid] = useState<number | null>(null);
  const [isCaptureModalOpen, setIsCaptureModalOpen] = useState(false);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  useGetEstimates(setestimates, setFetching,selectedFranchisee);


  useEffect(() => {
    getestimates(setestimates, setFetching,selectedFranchisee);
        },[selectedFranchisee])



  const handleUploadImages = (recordId: number, files: FileList | null) => {
    if (!files) return;
    const newFiles = Array.from(files);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: [...(prevFileLists[recordId] || []), ...newFiles],
    }));
    const fileNames = newFiles.map(file => file.name).join(", ");
    message.success(`Uploaded images for Estimate ID ${recordId}: ${fileNames}`);
  };

  const handleDeleteImage = (recordId: number, file: File) => {
    const newFileList = fileLists[recordId].filter(f => f !== file);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: newFileList
    }));
    message.success(`Deleted image ${file.name}`);
  };

  const handleSubmit = async (recordId: number) => {
    const headers = getAuthToken() as AuthTokenType;
    try {
      const formData = new FormData();
      formData.append("estimate_id", recordId.toString());
      fileLists[recordId]?.forEach(file => {
        formData.append("files", file);
      });
      await axios.post(estimatephotosUrl, formData, headers);
      message.success("Files submitted successfully");
    } catch (error) {
      console.error("Error submitting files:", error);
      message.error("Failed to submit files");
    }
  };

  const handleViewPhotos = (recordId: number) => {
    setselectedestimateid(recordId);
    setDrawerVisible(true);
  };

  const handleCapture = (dataUri: string, recordId: number) => {
    const byteString = atob(dataUri.split(',')[1]);
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const file = new File([ab], "camera_capture.jpeg", { type: mimeString });
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: [...(prevFileLists[recordId] || []), file],
    }));
    setIsCaptureModalOpen(false);
  };

  const getColumnFilterValues = (data: estimatesProps[], key: keyof estimatesProps) => {
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
};


  const estimateColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: 'Lead',
      dataIndex: 'lead_name',
      key: 'lead_name',
      filters: getColumnFilterValues(estimates,"lead_name").map((name) => ({ text: name, value: name })),
      onFilter: (value: string, record: estimatesProps) => record.lead_name === value,

    },
{
      title: "Actual Delivery Date",
      dataIndex: "actual_delivery_date",
      key: "actual_delivery_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Uploaded Files",
      key: "uploaded_files",
      render: (text: any, record: estimatesProps) => (
        <div>
          {fileLists[record.id]?.map((file: File, index: number) => (
            <span key={index}>
              {file.name}
              <Popconfirm
                title="Are you sure to delete this file?"
                onConfirm={() => handleDeleteImage(record.id, file)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ marginLeft: 8 }} />
              </Popconfirm>
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: estimatesProps) => (
        <>
          <a onClick={() => setIsCaptureModalOpen(true)}>Capture</a> |
          {isCaptureModalOpen && (
            <Modal
              title="Capture Photo"
              visible={isCaptureModalOpen}
              onCancel={() => setIsCaptureModalOpen(false)}
              footer={null}
              width={1000} // Set the width of the modal
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                <div style={{ maxWidth: '800px', maxHeight: '600px', width: '100%' }}>
                  <Camera
                    idealResolution={{ width: 640, height: 480 }}
                    onTakePhoto={(dataUri) => handleCapture(dataUri, record.id)}
                  />
                </div>
              </div>
            </Modal>
          )}
          <a
            onClick={(e) => document.getElementById(`file-upload-${record.id}`)?.click()}
          >
            Upload
          </a> |
          <input
            id={`file-upload-${record.id}`}
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={(e) => handleUploadImages(record.id, e.target.files)}
          />
          <a onClick={() => handleSubmit(record.id)}>Submit</a> |{' '}
          {' '}{' '}
          <a onClick={() => handleViewPhotos(record.id)}>View</a>
        </>
      ),
    },
  ];


  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = estimates.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
     

  return (
    <>
      <div className="card">
        <div className="cardHeader">
          <h2 className="headContent">Estimates</h2>
          <div className="rightContent">

          <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
 {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
 )}
</div>

        </div>
        <br />
        <Table
          className="custom-table"
          dataSource={filteredData}
          columns={estimateColumns as any}
          size="small"
          loading={fetching}
        />
      </div>
      <EstimatePhotosDrawer
        estimateId={selectedestimateid}
        visible={drawerVisible}
        onClose={() => {
          setselectedestimateid(null);
          setDrawerVisible(false);
        }}
      />
    </>
  );
};

export default EstimatePhotos;