import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification } from "antd";
import { useGetLocation } from "../utils/hooks";
import { AuthTokenType, location } from "../utils/types";
import { getAuthToken, getLocation } from "../utils/functions";
import AddLocationForm from "../components/AddLocationForm";
import axios from "axios";
import { locationsUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";

const Locations: FC = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: 'Franchisee',
      dataIndex: ['franchisee','name'],
      key: ['franchisee','name'],
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: location) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [location, setLocation] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingLocation, setEditingLocation] = useState<location | null>(null);

  useGetLocation(setLocation, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingLocation) {
      setDrawerVisible(true);
    }
  }, [editingLocation]);

  const handleEdit = (record: location) => {
    setEditingLocation(record);
  };

  const handleDelete = (locationId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this location?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${locationsUrl}/${locationId}/`, headers);

          // After successful deletion, fetch updated locations
          getLocation(setLocation, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingLocation(null); // Reset editingCategory when form is closed without editing
  };

  const onCreateLocation = () => {
    setDrawerVisible(true);
    setEditingLocation(null); // Reset editingLocation for new location
  };

  return (
    <ContentLayout
      pageTitle="Locations"
      buttontitle="Add Location"
      setModalState={setDrawerVisible}
      dataSource={location as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
      <AddLocationForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getLocation(setLocation, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingLocation={editingLocation}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Locations;