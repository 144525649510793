import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Select } from "antd";
import { useGetProduct, useGetCategory, useGetCurrency, useGetUnitofmeasure, useGetPricebyLocation } from "../utils/hooks";
import { AuthTokenType, product, unitofmeasure, category, currency, DataProps, Pricebylocation } from "../utils/types";
import { getAuthToken, getPricebyLocation, getProduct } from "../utils/functions";
import AddProductForm from "../components/AddProductForm";
import axios from "axios";
import { PricebyLocationUrl, productsUrl } from "../utils/network";
import AddPriceByLocation from "../components/AddPriceByLocationForm";
import ContentLayout from "../components/ContentLayout";



const PricebyLocation: FC = () => {
  const { Option } = Select;
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Product",
      dataIndex: "sku_name",
      key: "sku_name",
    },
    {
      title: "Franchisee",
      dataIndex: "franchisee_name",
      key: "franchisee_name",
    },

    {
      title: "Price",
      dataIndex: "sku_price",
      key: "sku_price",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: Pricebylocation) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [pricebylocations, setpricebylocations] = useState<Pricebylocation[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [formVisible, setFormVisible] = useState(false);
  const [editingpricebylocations, setEditingpricebylocations] = useState<Pricebylocation | null>(null);



  useGetPricebyLocation(setpricebylocations, setFetching);

  useEffect(() => {
    // When editingProduct changes, open the form
    if (editingpricebylocations) {
      setFormVisible(true);
    }
  }, [editingpricebylocations]);

  const handleEdit = (record: Pricebylocation) => {
    setEditingpricebylocations(record);
  };
  const onCloseWithoutEditing = () => {
    setEditingpricebylocations(null); // Reset editingCategory when form is closed without editing
  };

  const handleDelete = (productId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this product?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a product
          await axios.delete(`${PricebyLocationUrl}/${productId}/`, headers);

          // After successful deletion, fetch updated products
          getPricebyLocation(setpricebylocations, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };


  return (
    <ContentLayout
      pageTitle="Price by Location"
      buttontitle="Add Price"
      setModalState={setFormVisible}
      dataSource={pricebylocations as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
      <AddPriceByLocation
        onSuccessCallBack={() => {
          setFormVisible(false);
          getPricebyLocation(setpricebylocations, setFetching);
        }}
        isVisible={formVisible}
        onClose={() => setFormVisible(false)}
        editingPricebyLocation={editingpricebylocations}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default PricebyLocation;