import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, InputNumber, Select, Button } from "antd";
import { AuthTokenType, DataProps, AddProductFormProps, unitofmeasure, category, currency } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { productsUrl } from "../utils/network";
import { useGetCategory, useGetCurrency, useGetUnitofmeasure } from "../utils/hooks";
import { useRef } from 'react';
const ImageHolder: string = require("../assets/ImageHolder.svg").default;

const AddProductForm: FC<AddProductFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingProduct,
  onCloseWithoutEditing, // new prop
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<category[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [imageloading, setImageLoading] = useState(false);
  const fileSelect = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useGetCategory(setCategories, setFetching);

  const [currency, setCurrency] = useState<currency[]>([]);

  useGetCurrency(setCurrency, setFetching);

  const [unitofmeasures, setUnitofmeasures] = useState<unitofmeasure[]>([]);

  useGetUnitofmeasure(setUnitofmeasures, setFetching);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    // Populate form fields if editing a product
    if (editingProduct) {
      form.setFieldsValue({
        ...editingProduct,
        sku_weight_uom_id: editingProduct.sku_weight_uom.id,
        sku_length_uom_id: editingProduct.sku_length_uom.id,
        sku_height_uom_id: editingProduct.sku_height_uom.id,
        sku_uom_id: editingProduct.sku_uom.id,
        category_id: editingProduct.category.id,
        currency_id: editingProduct.currency.id,
      });
      // Set the image URL if the product has an image
      setImageUrl(editingProduct.image || null);
    } else {
      form.resetFields();
      setImageUrl(null); // Reset the image URL when adding a new product
    }
  }, [editingProduct, form]);


  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const selectedImage = fileList[0];
      setImage(selectedImage); // Update state with the selected file

      // Create a URL object for the selected image and set it as the background
      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let formData = new FormData(); // Create a new FormData object

      // Append other form values to the FormData object
      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null  && key !== 'image') { // Check if the value is neither undefined nor null
          formData.append(key, value.toString()); // Convert value to string before appending
        }
      });

      // Append the image file to the FormData object
      if (image) {
        formData.append('image', image);
      }
      let response: AxiosResponse;

      if (editingProduct) {
        // Editing product
        response = await axios.put(`${productsUrl}/${editingProduct.id}/`, formData, headers);
      } else {
        // Adding new product
        response = await axios.post(productsUrl + '/', formData, headers);
      }

      setLoading(false);
      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: 'Operation Error',
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingProduct ? "Edit Product" : "Add Product"}
      visible={isVisible}
      onClose={handleFormClose}

      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Product Photo"
          name="image"
        >
          <div
            className="imageview"
            onClick={() => !loading && fileSelect.current?.click()}
            style={{
              backgroundImage: `url(${imageUrl ? imageUrl : ImageHolder})`
            }}
          />
          <input type="file" style={{ display: "none" }} ref={fileSelect} onChange={handleFileChange} />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the product name!' }]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true, message: 'Please input the product code!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          rules={[{ required: true, message: 'Please input the SKU Color!' }]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="SKU Weight"
          name="sku_weight"
          rules={[{ required: true, message: 'Please input the SKU weight!' }]}
        >
          <InputNumber />
        </Form.Item>
  
        <Form.Item
          label="SKU Weight UOM"
          name="sku_weight_uom_id"
          rules={[{ required: true, message: 'Please select the SKU weight UOM!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a unit of measure</Select.Option>
            {unitofmeasures.map(uom => (
              <Select.Option key={uom.id} value={uom.id}>
                {uom.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label="SKU Length"
          name="sku_length"
          rules={[{ required: true, message: 'Please input the SKU length!' }]}
        >
          <InputNumber />
        </Form.Item>
  
        <Form.Item
          label="SKU Length UOM"
          name="sku_length_uom_id"
          rules={[{ required: true, message: 'Please select the SKU length UOM!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a unit of measure</Select.Option>
            {unitofmeasures.map(uom => (
              <Select.Option key={uom.id} value={uom.id}>
                {uom.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label="SKU Height"
          name="sku_height"
          rules={[{ required: true, message: 'Please input the SKU height!' }]}
        >
          <InputNumber />
        </Form.Item>
  
        <Form.Item
          label="SKU Height UOM"
          name="sku_height_uom_id"
          rules={[{ required: true, message: 'Please select the SKU height UOM!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a unit of measure</Select.Option>
            {unitofmeasures.map(uom => (
              <Select.Option key={uom.id} value={uom.id}>
                {uom.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label="SKU Description"
          name="sku_description"
          rules={[{ required: true, message: 'Please input the SKU description!' }]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="SKU UOM"
          name="sku_uom_id"
          rules={[{ required: true, message: 'Please select the SKU UOM!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a unit of measure</Select.Option>
            {unitofmeasures.map(uom => (
              <Select.Option key={uom.id} value={uom.id}>
                {uom.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label="SKU Buy Cost"
          name="sku_buy_cost"
          rules={[{ required: true, message: 'Please input the SKU buy cost!' }]}
        >
          <InputNumber />
        </Form.Item>
  
        <Form.Item
          label="SKU Price"
          name="sku_price"
          rules={[{ required: true, message: 'Please input the SKU price!' }]}
        >
          <InputNumber />
        </Form.Item>
  
        <Form.Item
          label="Category"
          name="category_id"
          rules={[{ required: true, message: 'Please select the category!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a category</Select.Option>
            {categories.map(category => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label="Currency"
          name="currency_id"
          rules={[{ required: true, message: 'Please select the currency!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a currency</Select.Option>
            {currency.map(curr => (
              <Select.Option key={curr.id} value={curr.id}>
                {curr.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingProduct ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddProductForm;