import React, { useState } from "react";
import { Drawer, Select, Button, notification } from "antd";
import { AuthTokenType, UserProps, salesProps } from "../utils/types";
import { useGetUsers } from "../utils/hooks";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { salesorderUrl, salesorderViewwithemployeeupdateUrl, salesorderwithemployeeUrl } from "../utils/network";

interface EmployeeSelectionDrawerProps {
  id?:number;  
  visible: boolean;
  onClose: () => void;
  onUpdateEmployee: () => void; 
}

const EmployeeSelectionDrawer: React.FC<EmployeeSelectionDrawerProps> = ({
  id,
    visible,
  onClose,
  onUpdateEmployee,
}) => {
  const [users, setUsers] = useState<UserProps[] | undefined>();
  const [fetching, setFetching] = useState(true);

  useGetUsers(setUsers, setFetching);

  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);

  const handleEmployeeSelect = (value: number) => {
    setSelectedEmployee(value);
  };
  const [loading,setLoading]=useState(false)       

  const handleSubmit = async () => {
    if (selectedEmployee !== null) {
        const datatosend={
            id:id,
            employee_id:selectedEmployee
          }
        const headers=getAuthToken() as AuthTokenType
          setLoading(true)
        
          const response:AxiosResponse =await axios.patch(salesorderViewwithemployeeupdateUrl+'/'+datatosend.id,datatosend,headers).catch(
              (e)=>{ notification.error({
                  message:"Operation Error",
                  description:e.response?.data.error
              })
        
          }
              ) as AxiosResponse
        
              setLoading(false)
        
              if(response){
                  notification.success({
                      message:"Operation Success",
                      description:"Employee Alloted"
                      
                  })
        
              }
      setSelectedEmployee(null);
      onUpdateEmployee(); // Call the callback function to update sales orders
      onClose();
    }
  };

  const handleClose = () => {
    setSelectedEmployee(null);
    onClose();
  };

  return (
    <Drawer
      title="Select Employee"
      placement="right"
      onClose={handleClose}
      visible={visible}
      width={400}
    >
      <Select
        placeholder="Select employee"
        onChange={handleEmployeeSelect}
        style={{ width: "100%", marginBottom: 20 }}
        disabled={!users} // Disable the select until users are fetched
      >
        {users?.map((employee) => ( // Optional chaining to handle undefined users
          <Select.Option key={employee.id} value={employee.id}>
            {employee.fullname}
          </Select.Option>
        ))}
      </Select>
      <div style={{ textAlign: "right" }}>
        <Button style={{ marginRight: 10 }} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Drawer>
  );
};

export default EmployeeSelectionDrawer;